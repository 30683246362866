import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import SocialWidget from "../Widget/SocialWidget";
import Newsletter from "../Widget/Newsletter";
import "./header.scss";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import Div from "../Div";

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);
  const isAuthenticated = localStorage.getItem("access_token");
  console.log(isAuthenticated !== null);

  if (isAuthenticated !== null) {
    return (
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ""
        } cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
              </Div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? "block" : "none"}` }}
                  >
                    <li>
                      <NavLink
                        to="/auth/nba"
                        onClick={() => setMobileToggle(false)}
                      >
                        NBA
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/auth/mlb"
                        onClick={() => setMobileToggle(false)}
                      >
                        MLB
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/auth/ev"
                        onClick={() => setMobileToggle(false)}
                      >
                        +EV
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/auth/nfl"
                        onClick={() => setMobileToggle(false)}
                      >
                        NFL
                      </NavLink>
                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? "cs-munu_toggle cs-toggle_active"
                        : "cs-munu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-toolbox">
                  <NavLink
                    to="/auth/Profile"
                    onClick={() => setMobileToggle(false)}
                  >
                    <span
                      className="cs-icon_btn"
                      onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                    >
                      <svg
                        width="26px"
                        height="26px"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#000000"
                        stroke-width="0.00016"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7Z"
                            fill="#ffffff"
                          ></path>{" "}
                          <path
                            d="M14 12C14 10.3431 12.6569 9 11 9H5C3.34315 9 2 10.3431 2 12V15H14V12Z"
                            fill="#ffffff"
                          ></path>{" "}
                        </g>
                      </svg>
                    </span>
                  </NavLink>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </header>
    );
  } else {
    return (
      <>
        <header
          className={`cs-site_header cs-style1 text-uppercase ${
            variant ? variant : ""
          } cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}
        >
          <Div className="cs-main_header">
            <Div className="container">
              <Div className="cs-main_header_in">
                <Div className="cs-main_header_left">
                  <Link
                    style={{
                      display: "flex",
                      fontSize: "20px",
                      fontFamily: "'Poppins'",
                      fontWeight: "700",
                    }}
                    className="cs-site_branding"
                    to="/"
                  >
                    <span
                      style={{
                        fontSize: "83px",
                        marginTop: "10px",
                        fontFamily: "DM Sans",
                      }}
                      className="brandingSpan"
                    >
                      G
                    </span>
                    <div>
                      <span className="brandingSpan">ambling</span>
                      <br />
                      <span>Forecast</span>
                    </div>
                  </Link>
                </Div>
                <Div className="cs-main_header_center">
                  <Div className="cs-nav cs-primary_font cs-medium">
                    <ul
                      className="cs-nav_list"
                      style={{ display: `${mobileToggle ? "block" : "none"}` }}
                    >
                      <li>
                        <NavLink to="/" onClick={() => setMobileToggle(false)}>
                          Home
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="about"
                          onClick={() => setMobileToggle(false)}
                        >
                          About
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="pricing"
                          onClick={() => setMobileToggle(false)}
                        >
                          Pricing
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="contact"
                          onClick={() => setMobileToggle(false)}
                        >
                          Contact
                        </NavLink>
                        
                      </li>
                    </ul>
                    <span
                      className={
                        mobileToggle
                          ? "cs-munu_toggle cs-toggle_active"
                          : "cs-munu_toggle"
                      }
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      <span></span>
                    </span>
                  </Div>
                </Div>
                <Div className="cs-main_header_right">
                  <Div className="cs-toolbox">
                    <span
                      className="cs-icon_btn"
                      onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                    >
                      <span className="cs-icon_btn_in">
                        <span />
                        <span />
                        <span />
                        <span />
                      </span>
                    </span>
                  </Div>
                </Div>
              </Div>
            </Div>
          </Div>
        </header>

        <Div
          className={
            sideHeaderToggle ? "cs-side_header active" : "cs-side_header"
          }
        >
          <button
            className="cs-close"
            onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
          />
          <Div
            className="cs-side_header_overlay"
            onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
          />
          <Div className="cs-side_header_in">
            <Div className="cs-side_header_shape" />
            <Link
              style={{
                display: "flex",
                fontSize: "20px",
                fontFamily: "'Poppins'",
                fontWeight: "700",
              }}
              className="cs-site_branding"
              to="/"
            >
              <span
                style={{
                  fontSize: "83px",
                  marginTop: "10px",
                  fontFamily: "DM Sans",
                }}
                className="brandingSpan"
              >
                G
              </span>
              <div>
                <span className="brandingSpan">ambling</span>
                <br />
                <span>Forecast</span>
              </div>
            </Link>
            <Div className="cs-side_header_box">
              <h2 className="cs-side_header_heading">
                Do you have any query <br />in your mind? Keep connect us.
              </h2>
            </Div>
            <Div className="cs-side_header_box">
              <ContactInfoWidget title="Contact Us" withIcon />
            </Div>
            <Div className="cs-side_header_box">
              <SocialWidget />
            </Div>
          </Div>
        </Div>
      </>
    );
  }
}
