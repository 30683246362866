import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axiosRest';
import Confetti from 'react-confetti';

export default function Success() {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const [status, setStatus] = useState(null);
  const [countdown, setCountdown] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    const verifySubscription = async () => {
      try {
        const response = await axiosInstance.get(`/payment/subscription-success/?session_id=${sessionId}`);
        setStatus(response.data.message);
      } catch (error) {
        console.error('Error verifying subscription:', error);
        setStatus('Failed to verify subscription.');
      }
    };

    if (sessionId) {
      verifySubscription();
    }

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const redirectTimeout = setTimeout(() => {
      navigate('/auth'); // Redirect using react-router-dom
    }, 3000);

    return () => {
      clearInterval(countdownInterval);
      clearTimeout(redirectTimeout);
    };
  }, [sessionId, navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <Confetti />
      <h1>Subscription Successful!</h1>
      {status ? <p>Status: {status}</p> : <p>Verifying your subscription...</p>}
      <p>Redirecting in {countdown} seconds...</p>
    </div>
  );
}
