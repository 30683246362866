import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';

export default function PageHeading({ title, bgSrc, pageLinkText, auth }) {
  const linkc = auth ? "/auth" : "/";
  return (
    <Div
      className="cs-page_heading cs-style1 cs-center cs-bg"
      style={{ backgroundImage: `url(${bgSrc})` }}
    >
      <Div className="container">
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h1 className="cs-page_title cs-font_50 cs-white_color">{title}</h1>
          <ol className="breadcrumb text-uppercase">
            <li className="breadcrumb-item">
              <Link to={linkc}>Home</Link>
            </li>
            <li className="breadcrumb-item active">{pageLinkText}</li>
          </ol>
        </div>
      </Div>
    </Div>
  );
}
