import React, { useState } from "react";
import Spacing from "../../components/Spacing";
import axiosInstance from "../../api/axiosRest";
import PageHeading from "../../components/PageHeading"; 
import Div from "../../components/Div";
import { Icon } from "@iconify/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    old_password: '',
    new_password: '',
    new_password2: ''
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear messages when user starts typing
    // Since we're using toasts, no need to manage errorMessage and infoMessage
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { old_password, new_password, new_password2 } = formData;

    // Basic validation
    if (!old_password || !new_password || !new_password2) {
      toast.error("Please fill in all fields.");
      return;
    }

    if (new_password !== new_password2) {
      toast.error("New passwords do not match.");
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.put("/change-password/", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      toast.success(response.data.detail || "Password changed successfully.");
      setFormData({
        old_password: "",
        new_password: "",
        new_password2: "",
      });
    } catch (error) {
      console.error("Error changing password:", error);
      if (error.response && error.response.data) {
        // Aggregate error messages
        const errors = Object.values(error.response.data).flat();
        toast.error(errors.join(" "));
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <PageHeading
      title="Change Password"
      bgSrc="/images/profile_hero_bg.jpeg"
      pageLinkText="Change Password"
      auth="/auth"
    />
      {/* Toast Container */}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {/* <Spacing lg="70" md="0" /> */}
      <div style={{ padding: "20px 40px" }}>
        <div
          className="row"
          style={{
            background: "#151515",
            borderRadius: "6px",
            padding: "20px 30px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h4 style={{ fontFamily: "sans-serif", margin: "15px 0", color: "white" }}>
              Change Password
            </h4>
          </div>
          <div className="col-md-12">
            <div className="ProfileFormContainer" style={{ color: "white" }}>
              {/* Removed Info and Error Message Divs */}
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-12">
                    <label htmlFor="old_password" className="mb-1">
                      Old Password
                    </label>
                    <div style={{ position: 'relative' }}>
                      <input
                        type={showOldPassword ? 'text' : 'password'}
                        id="old_password"
                        name="old_password"
                        placeholder="Enter old password"
                        className="cs-form_field"
                        style={{ borderRadius: '.25rem' }}
                        value={formData.old_password}
                        onChange={handleChange}
                        required
                      />
                      <button
                        type="button"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          color: '#fff' // Ensure button text is visible on dark background
                        }}
                      >
                        {showOldPassword ? 'Hide' : 'Show'}
                      </button>
                    </div>
                    <Spacing lg="20" md="20" />
                  </div>
                  <div className="col-sm-12">
                    <label htmlFor="new_password" className="mb-1">
                      New Password
                    </label>
                    <div style={{ position: 'relative' }}>
                      <input
                        type={showNewPassword ? 'text' : 'password'}
                        id="new_password"
                        name="new_password"
                        placeholder="Enter new password"
                        className="cs-form_field"
                        style={{ borderRadius: '.25rem' }}
                        value={formData.new_password}
                        onChange={handleChange}
                        required
                      />
                      <button
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          color: '#fff' // Ensure button text is visible on dark background
                        }}
                      >
                        {showNewPassword ? 'Hide' : 'Show'}
                      </button>
                    </div>
                    <Spacing lg="20" md="20" />
                  </div>
                  <div className="col-sm-12">
                    <label htmlFor="new_password2" className="mb-1">
                      Confirm New Password
                    </label>
                    <div style={{ position: 'relative' }}>
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="new_password2"
                        name="new_password2"
                        placeholder="Confirm new password"
                        className="cs-form_field"
                        style={{ borderRadius: '.25rem' }}
                        value={formData.new_password2}
                        onChange={handleChange}
                        required
                      />
                      <button
                        type="button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          color: '#fff' // Ensure button text is visible on dark background
                        }}
                      >
                        {showConfirmPassword ? 'Hide' : 'Show'}
                      </button>
                    </div>
                    <Spacing lg="20" md="20" />
                  </div>
                </div>
                <div className="col-sm-12">
                  <button
                    type="submit"
                    style={{ width: '100%', borderRadius: '.25rem' }}
                    className="cs-btn cs-style1 mb-4"
                    disabled={loading}
                  >
                    {loading ? 'Updating...' : 'Update'}
                    <Icon icon="bi:arrow-right" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
