import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import CustomCursor from "../CustomCursor";
import Sidebar from "../Sidebar.jsx";

const AuthLayout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options = [
    {
      name: "=",
      scroll: true,
      backdrop: false,
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      {options.map((props, idx) => (
        <Sidebar key={idx} {...props} />
      ))}
      <div className="AuthContent">
        <Outlet />
      </div>
      <CustomCursor />
    </div>
  );
};

export default AuthLayout;
