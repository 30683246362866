import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { FaPen } from "react-icons/fa";
import "./ManageSubscription.scss";

const CreditCard = ({ number, name, expiry, cvc, onEditClick }) => {
  return (
    <div className="CardDebit">
      <Cards number={number} name={name} expiry={expiry} cvc="***" focused="number" />
      <button
        className="EditButton"
        onClick={onEditClick}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <FaPen color="white" size={20} />
      </button>
    </div>
  );
};

export default CreditCard;
