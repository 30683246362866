import React, { createContext, useState, useEffect } from "react";
import axiosInstance from "../api/axiosRest";
import { SUBSCRIPTION } from "../api/urls";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
      isAuthenticated: false,
      isSubscribed: false,
      isLoading: true,
    });
  
    const checkAuthAndSubscription = async () => {
      const token = localStorage.getItem("access_token");
      if (!token) {
        setAuthState({ isAuthenticated: false, isSubscribed: false, isLoading: false });
        return;
      }
  
      setAuthState((prevState) => ({ ...prevState, isAuthenticated: true }));
  
      try {
        const response = await axiosInstance.get(SUBSCRIPTION.STATUS);
        console.log(response.data);
        const { subscriptions, cards, billing_history } = response.data;
        console.log(subscriptions);
  
        if (subscriptions === "none" || (Array.isArray(subscriptions) && subscriptions.length === 0)) {
          setAuthState((prevState) => ({ ...prevState, isSubscribed: false, isLoading: false }));
        } else {
          setAuthState((prevState) => ({ ...prevState, isSubscribed: true, isLoading: false }));
          localStorage.setItem("subscription_data", JSON.stringify(subscriptions));
          localStorage.setItem("cards", JSON.stringify(cards));
          localStorage.setItem("billing_history", JSON.stringify(billing_history));
        }
      } catch (error) {
        console.error("Error checking subscription status:", error);
        setAuthState((prevState) => ({ ...prevState, isSubscribed: false, isLoading: false }));
      }
    };
  
    useEffect(() => {
      checkAuthAndSubscription();
    }, []);
  
    const refreshAuthState = () => {
      checkAuthAndSubscription();
    };
  
    return (
      <AuthContext.Provider value={{ ...authState, refreshAuthState }}>
        {children}
      </AuthContext.Provider>
    );
  };
  
