// src/components/Card.jsx
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import payment from 'payment';
import classNames from 'classnames';
import padEnd from 'lodash/padEnd';
import times from 'lodash/times';
import "./CreditCard.scss";

import images from '../../assets/card-images/index'; // Ensure this file has card images for different types

const cardType = payment.fns.cardType;

class Card extends Component {
  state = { type: 'unknown', length: 16 };

  componentDidMount() {
    this.updateType(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateType(nextProps);
  }

  displayClassName = (base) => {
    const { focused } = this.props;
    let classNames = [`react-credit-card__${base}`, 'react-credit-card__display'];

    if (focused === base) {
      classNames = [...classNames, 'react-credit-card--focused'];
    }

    return classNames.join(' ');
  };

  formatCVC = (cvc) => {
    const { type } = this.state;
    if (type === 'amex') {
      return padEnd(cvc, 4, '•');
    }
    return padEnd(cvc, 3, '•');
  };

  formatExpiry = (expiry) => {
    let formattedExpiry = expiry;
    if (!formattedExpiry) {
      return '••/••';
    }

    const maxLength = 6;
    if (formattedExpiry.match(/\//)) {
      formattedExpiry = formattedExpiry.replace('/', '');
    }
    if (!formattedExpiry.match(/^[0-9]*$/)) {
      return '••/••';
    }

    formattedExpiry = padEnd(formattedExpiry, 4, '•');
    formattedExpiry = `${formattedExpiry.slice(0, 2)}/${formattedExpiry.slice(2, maxLength)}`;

    return formattedExpiry;
  };

  formatName = (name) => {
    const { namePlaceholder } = this.props;
    if (namePlaceholder && !name) {
      return namePlaceholder;
    }
    return name;
  };

  formatNumber = (number) => {
    const { length: maxLength, type } = this.state;
    let formattedNumber = number;

    if (number && number.length > maxLength) {
      formattedNumber = number.slice(0, maxLength);
    }

    formattedNumber = padEnd(formattedNumber, maxLength, '•');

    if (type === 'amex') {
      formattedNumber = `${formattedNumber.substring(0, 4)} ${formattedNumber.substring(4, 10)} ${formattedNumber.substring(10)}`;
    } else {
      const amountOfSpaces = Math.ceil(maxLength / 4);
      times(amountOfSpaces, (i) => {
        const spaceIndex = i * 4 + i;
        formattedNumber = `${formattedNumber.slice(0, spaceIndex)} ${formattedNumber.slice(spaceIndex)}`;
      });
    }

    return formattedNumber;
  };

  updateType = (props) => {
    const type = props.type || cardType(props.number);
    if (!props.number) {
      this.setState({ type: 'unknown' });
    } else if (type) {
      this.setState({ type, length: type === 'amex' ? 15 : 16 });
    } else {
      this.setState({ type: 'unknown', length: 16 });
    }
  };

  render() {
    const {
      hideName,
      backDescriptionText,
      expiry,
      expiryBeforeText,
      expiryAfterText,
      focused,
      cvc,
      name,
      number,
    } = this.props;
    const { type } = this.state;
    const isAmex = (type === 'amex');

    return (
      <div className="react-credit-card__container">
        <div
          className={classNames(
            'react-credit-card',
            type ? `react-credit-card--${type}` : '',
            focused === 'cvc' && !isAmex ? 'react-credit-card--flipped' : ''
          )}
        >
          <div className="react-credit-card__front">
            <div className="react-credit-card__lower">
              {type !== 'unknown' && (
                <img
                  src={images[type]}
                  alt={type}
                  className={classNames('react-credit-card__logo', `react-credit-card--${type}`)}
                />
              )}
              <div className={this.displayClassName('number')}>{this.formatNumber(number)}</div>
              {!hideName && (
                <div className={this.displayClassName('name')}>{this.formatName(name)}</div>
              )}
              <div
                className={this.displayClassName('expiry')}
                data-before={expiryBeforeText}
                data-after={expiryAfterText}
              >
                {this.formatExpiry(expiry)}
              </div>
            </div>
          </div>
          <div className="react-credit-card__back">
            <div className="react-credit-card__bar" />
            <div className={this.displayClassName('cvc')}>{this.formatCVC(cvc)}</div>
          </div>
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  hideName: PropTypes.bool,
  number: PropTypes.string,
  cvc: PropTypes.string,
  name: PropTypes.string,
  expiry: PropTypes.string,
  focused: PropTypes.string,
  namePlaceholder: PropTypes.string,
  expiryBeforeText: PropTypes.string,
  expiryAfterText: PropTypes.string,
  backDescriptionText: PropTypes.string,
};

Card.defaultProps = {
  hideName: false,
  number: '',
  cvc: '',
  name: '',
  expiry: '',
  focused: null,
  namePlaceholder: 'FULL NAME',
  expiryBeforeText: 'month/year',
  expiryAfterText: 'valid thru',
  backDescriptionText: '',
};

export default Card;
