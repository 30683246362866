// src/components/Button.jsx

import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function Button({ btnLink, btnText, variant, icon, onClick }) {
  if (btnLink) {
    return (
      <Link
        to={btnLink}
        className={variant ? `cs-text_btn ${variant} custom-login` : 'cs-text_btn custom-login'}
      >
        <span>{btnText}</span>
        {icon ? icon : <Icon icon="bi:arrow-right" />}
      </Link>
    );
  }

  return (
    <button
      className={variant ? `cs-text_btn ${variant} custom-login` : 'cs-btn cs-style1'}
      style={{padding: '19px', fontSize: '18px'}}
      onClick={onClick}
      type="button"
    >
      <span>{btnText}</span>
      {icon ? icon : <Icon icon="bi:arrow-right" />}
    </button>
  );
}

Button.propTypes = {
  btnLink: PropTypes.string,
  btnText: PropTypes.string.isRequired,
  variant: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  btnLink: null,
  variant: '',
  icon: null,
  onClick: null,
};