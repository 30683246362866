import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { pageTitle } from "../helper";
import PageHeading from "../components/PageHeading";
import Div from "../components/Div";
import Spacing from "../components/Spacing";
import { Icon } from "@iconify/react";
import { useNavigate, Link } from "react-router-dom";
import axiosInstance from "../api/axiosRest"; 
import { AUTH } from "../api/urls"; 
import { AuthContext } from "../context/AuthContext";
import zxcvbn from "zxcvbn"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AuthPage() {
  pageTitle("Login | Sign Up");

  const [activeForm, setActiveForm] = useState('login'); // 'login' or 'signup'
  // Removed loginError and registerError state variables
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    phoneNumber: "",
    email: "",
    password: "",
    password2: "",
    address: "",
    referralCode: "",
  });

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordFeedback, setPasswordFeedback] = useState("");
  const location = useLocation(); 
  const { refreshAuthState } = useContext(AuthContext);

  const navigate = useNavigate();

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Handle input changes for login form
  const handleLoginInputChange = (field, value) => {
    // Removed setLoginError
    setLoginData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // Handle input changes for registration form
  const handleRegisterInputChange = (field, value) => {
    // Removed setRegisterError
    setRegisterData((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    // If the password field is being updated, evaluate its strength
    if (field === "password") {
      const evaluation = zxcvbn(value);
      setPasswordStrength(evaluation.score);
      setPasswordFeedback(evaluation.feedback.warning || evaluation.feedback.suggestions.join(" "));
    }
  };

  // Handle login form submission
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    // Removed setLoginError

    const { email, password } = loginData;

    // Basic validation
    if (!email || !password) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      const response = await axiosInstance.post(
        AUTH.LOGIN,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log('Login response:', response.data); // Debugging line

      // Adjust destructuring based on actual response structure
      const { access, refresh } = response.data.token; // Ensure 'token' exists
      if (!access || !refresh) {
        throw new Error("Tokens not found in response.");
      }

      localStorage.setItem("access_token", access);
      localStorage.setItem("refresh_token", refresh);
      refreshAuthState();
      toast.success("Login successful!");
      navigate("/auth/profile"); 
      // ProtectedRoute will handle further redirection based on subscription
    } catch (error) {
      if (error.response && error.response.data) {
        // Extract error message
        const errorMessage = error.response.data.errors?.non_field_errors?.[0] || "Login failed. Please try again.";
        toast.error(errorMessage);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referral = searchParams.get("referral") || "";

    if (referral) {
      setRegisterData((prev) => ({
        ...prev,
        referralCode: referral,
      }));
    }
  }, [location.search]);

  // Handle registration form submission
  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    // Removed setRegisterError

    const {
      firstName,
      lastName,
      username,
      phoneNumber,
      email,
      password,
      password2,
      address,
    } = registerData;

    // Basic validation
    if (
      !firstName ||
      !lastName ||
      !username ||
      !email ||
      !password ||
      !password2
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (password !== password2) {
      toast.error("Passwords do not match.");
      return;
    }

    // Password strength validation
    if (passwordStrength < 2) { // Adjust threshold as needed
      toast.error("Please choose a stronger password.");
      return;
    }

    try {
      const response = await axiosInstance.post(
        AUTH.REGISTER,
        {
          first_name: firstName,
          last_name: lastName,
          username,
          phone: phoneNumber,
          email,
          password,
          password2,
          address,
          referral_code: registerData.referralCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Adjust destructuring based on actual response structure
      const { access, refresh } = response.data.token; // Ensure 'token' exists
      if (!access || !refresh) {
        throw new Error("Tokens not found in response.");
      }

      localStorage.setItem("access_token", access);
      localStorage.setItem("refresh_token", refresh);

      refreshAuthState();
      toast.success("Registration successful!");
      navigate("/auth/profile"); 
      // ProtectedRoute will handle further redirection based on subscription
    } catch (error) {
      console.error(error.response);
      if (error.response && error.response.data) {
        // Handle validation errors from the backend
        const errors = Object.values(error.response.data).flat();
        toast.error(errors.join(" "));
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      {/* Toast Container */}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <PageHeading
        title="Login | Sign Up"
        bgSrc="/images/blog_hero_bg.jpeg"
        pageLinkText="Login | Sign Up"
      />
      <Spacing lg="100" md="70" />

      {/* Toggle Button */}
      <Div className="container">
        <Div className="row justify-content-center">
          <Div className="col-lg-6 text-center">
            <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font justify-content-center" style={{ position: "static"}}>
              <li 
                className={activeForm === 'login' ? "active mr-3" : "mr-3"} 
                onClick={() => setActiveForm('login')}
                style={{ cursor: 'pointer' }}
              >
                Login
              </li>
              <li 
                className={activeForm === 'signup' ? "active" : ""} 
                onClick={() => setActiveForm('signup')}
                style={{ cursor: 'pointer' }}
              >
                Sign Up
              </li>
            </ul>
          </Div>
        </Div>
      </Div>

      <Spacing lg="20" md="20" />

      {/* Forms */}
      <Div className="container">
        <Div className="row justify-content-center">
          <Div className="col-lg-6">
            {activeForm === 'login' && (
              <form onSubmit={handleLoginSubmit} className="row">
                <Div className="col-sm-12">
                  <label htmlFor="loginEmail" className="cs-primary_color">
                    Email
                  </label>
                  <input
                    type="email"
                    id="loginEmail"
                    className="cs-form_field"
                    value={loginData.email}
                    onChange={(e) =>
                      handleLoginInputChange("email", e.target.value)
                    }
                    placeholder="Enter Email"
                    required
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-12">
                  <label htmlFor="loginPassword" className="cs-primary_color">
                    Password
                  </label>
                  <input
                    type="password"
                    id="loginPassword"
                    className="cs-form_field"
                    value={loginData.password}
                    onChange={(e) =>
                      handleLoginInputChange("password", e.target.value)
                    }
                    placeholder="Enter Password"
                    required
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-12">
                  {/* Forgot Password Link */}
                  <Div className="d-flex justify-content-end">
                    <Link to="/password-reset" className="cs-forgot-password-link">
                      Forgot Password?
                    </Link>
                  </Div>
                  <Spacing lg="10" md="10" />
                </Div>
                <Div className="col-sm-12">
                  <button type="submit" className="cs-btn cs-style1">
                    <span>Login</span>
                    <Icon icon="bi:arrow-right" />
                  </button>
                  {/* Removed alert box for loginError */}
                </Div>
              </form>
            )}

            {activeForm === 'signup' && (
              <form onSubmit={handleRegisterSubmit} className="row">
                <Div className="col-sm-6">
                  <label htmlFor="firstName" className="cs-primary_color">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="cs-form_field"
                    value={registerData.firstName}
                    onChange={(e) =>
                      handleRegisterInputChange("firstName", e.target.value)
                    }
                    placeholder="Enter First Name"
                    required
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label htmlFor="lastName" className="cs-primary_color">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="cs-form_field"
                    value={registerData.lastName}
                    onChange={(e) =>
                      handleRegisterInputChange("lastName", e.target.value)
                    }
                    placeholder="Enter Last Name"
                    required
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label htmlFor="username" className="cs-primary_color">
                    Username
                  </label>
                  <input
                    type="text"
                    id="username"
                    className="cs-form_field"
                    value={registerData.username}
                    onChange={(e) =>
                      handleRegisterInputChange("username", e.target.value)
                    }
                    placeholder="Enter Username"
                    required
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label htmlFor="phoneNumber" className="cs-primary_color">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    className="cs-form_field"
                    value={registerData.phoneNumber}
                    onChange={(e) =>
                      handleRegisterInputChange("phoneNumber", e.target.value)
                    }
                    placeholder="Enter Phone Number"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-12">
                  <label htmlFor="registerEmail" className="cs-primary_color">
                    Email
                  </label>
                  <input
                    type="email"
                    id="registerEmail"
                    className="cs-form_field"
                    value={registerData.email}
                    onChange={(e) =>
                      handleRegisterInputChange("email", e.target.value)
                    }
                    placeholder="Enter Email"
                    required
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label htmlFor="registerPassword" className="cs-primary_color">
                    Password
                  </label>
                  <input
                    type="password"
                    id="registerPassword"
                    className="cs-form_field"
                    value={registerData.password}
                    onChange={(e) =>
                      handleRegisterInputChange("password", e.target.value)
                    }
                    placeholder="Enter Password"
                    required
                  />
                  {/* Password Strength Meter */}
                  <div className="password-strength-meter">
                    <progress
                      className={`password-strength-meter-progress strength-${passwordStrength}`}
                      value={passwordStrength}
                      max="4"
                    />
                    <br />
                    <small className="password-feedback">
                      {passwordStrength === 0 && "Very Weak"}
                      {passwordStrength === 1 && "Weak"}
                      {passwordStrength === 2 && "Fair"}
                      {passwordStrength === 3 && "Good"}
                      {passwordStrength === 4 && "Strong"}
                    </small>
                  </div>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label htmlFor="password2" className="cs-primary_color">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="password2"
                    className="cs-form_field"
                    value={registerData.password2}
                    onChange={(e) =>
                      handleRegisterInputChange("password2", e.target.value)
                    }
                    placeholder="Confirm Password"
                    required
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-12">
                  <label htmlFor="address" className="cs-primary_color">
                    Address
                  </label>
                  <textarea
                    id="address"
                    name="address"
                    cols="30"
                    rows="3"
                    className="cs-form_field"
                    value={registerData.address}
                    onChange={(e) =>
                      handleRegisterInputChange("address", e.target.value)
                    }
                    placeholder="Enter your address"
                  ></textarea>
                  <Spacing lg="25" md="25" />
                </Div>
                <Div className="col-sm-12">
                  <label htmlFor="referralCode" className="cs-primary_color">
                    Referral Code (Optional)
                  </label>
                  <input
                    type="text"
                    id="referralCode"
                    className="cs-form_field"
                    value={registerData.referralCode || ""}
                    onChange={(e) =>
                      handleRegisterInputChange("referralCode", e.target.value)
                    }
                    placeholder="Enter Referral Code"
                  />
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-sm-12">
                  <button type="submit" className="cs-btn cs-style1">
                    <span>Sign Up</span>
                    <Icon icon="bi:arrow-right" />
                  </button>
                  {/* Removed alert box for registerError */}
                </Div>
              </form>
            )}
          </Div>
        </Div>
      </Div>
      <Spacing lg="50" md="30" />
    </>
  );
}
