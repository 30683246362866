import React, { useEffect, useState } from "react";
import "./ManageSubscription.scss";
import CreditCard from "./CreditCard";
import { FaFileInvoiceDollar, FaPen } from "react-icons/fa";
import { ProgressBar, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../components/PageHeading"; 
import PlanSelectionModal from "../../components/PlanModal/PlanSelectionModal";

const ManageSubscription = () => {
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState({
    daysToFinish: 0,
    plan: "",
    currentPlanId: null, // Add currentPlanId
    paymentDetails: [],
    billingHistory: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch Subscription, Payment, and Billing Data
  useEffect(() => {
    try {
      // Parse data from localStorage with safe fallbacks
      const subscriptions = JSON.parse(localStorage.getItem("subscription_data") || "[]");
      const cards = JSON.parse(localStorage.getItem("cards") || "[]");
      const billingHistory = JSON.parse(localStorage.getItem("billing_history") || "[]");
  
      console.log("Subscriptions:", subscriptions);
      console.log("Cards:", cards);
      console.log("Billing History:", billingHistory);
  
      let daysToFinish = 0;
      let currentPlan = "";
      let currentPlanId = null;
  
      // Ensure subscriptions is an array before processing
      if (Array.isArray(subscriptions) && subscriptions.length > 0) {
        // Find an active subscription
        const activeSubscription = subscriptions.find(
          (sub) => sub && sub.subscription_status === "active"
        );
  
        console.log("Active Subscription:", activeSubscription);
  
        if (activeSubscription) {
          const currentPeriodEnd = new Date(activeSubscription.current_period_end);
        
          if (!isNaN(currentPeriodEnd)) {
            const today = new Date();
            const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // Normalize to start of the day
            const periodEndStart = new Date(currentPeriodEnd.getFullYear(), currentPeriodEnd.getMonth(), currentPeriodEnd.getDate()); // Normalize to start of the day
            const diffTime = periodEndStart - todayStart; // Difference in milliseconds
            daysToFinish = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert to days
          } else {
            console.warn("Invalid date for current_period_end:", activeSubscription.current_period_end);
          }
        
          currentPlan = activeSubscription.plan_name || "Unknown Plan";
          currentPlanId = activeSubscription.plan_id || null; // Assuming plan_id exists
        }
      }
  
      // Ensure cards and billingHistory are arrays before setting state
      setSubscriptionData({
        daysToFinish,
        plan: currentPlan,
        currentPlanId,
        paymentDetails: Array.isArray(cards) ? cards : [],
        billingHistory: Array.isArray(billingHistory) ? billingHistory : [],
      });
    } catch (error) {
      console.error("Failed to fetch data from localStorage:", error);
      setErrorMessage("Failed to load subscription data.");
    }
  }, []);

  const { daysToFinish, plan, currentPlanId, paymentDetails, billingHistory } = subscriptionData;
  console.log(paymentDetails);

  const handleEditPlan = () => {
    console.log('Attempting to open modal...');
    setIsModalOpen(true);
  };

  const handlePlanSelect = (subscription) => {
    setInfoMessage("Subscription updated successfully.");
    setSubscriptionData((prevData) => ({
      ...prevData,
      plan: subscription.plan.name.charAt(0).toUpperCase() + subscription.plan.name.slice(1),
      daysToFinish: 30,
      currentPlanId: subscription.plan.id, // Update currentPlanId
    }));
    setIsModalOpen(false); // Close modal after selecting a plan
  };

  return (
    <>
      <PageHeading
        title="Manage Subscription"
        bgSrc="/images/profile_hero_bg.jpeg"
        pageLinkText="Manage Subscription"
        auth="/auth"
      />
      <div className="container" style={{ padding: "0" }}>
        <div style={{ padding: "20px 40px" }}>
          {infoMessage && (
            <div className="alert alert-success" role="alert">
              {infoMessage}
            </div>
          )}
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <div className="row" style={{ display: "flex", justifyContent: "space-evenly" }}>
            {/* Credit Card Section */}
            <div className="col-md-4 p-0 col-sm-12 CreditCardContainer">
              {paymentDetails && paymentDetails.length > 0 ? (
                paymentDetails.map((card, index) => (
                  <CreditCard
                    key={index}
                    number={`${card.last4}`}
                    name={card.name_on_card}
                    expiry={`${card.exp_month}/${card.exp_year}`}
                    onEditClick={() => navigate("/auth/payment-method")}
                  />
                ))
              ) : (
                <p>No Payment Method Added</p>
              )}
            </div>

            {/* Subscription Info Section */}
            <div className="col-md-8 ManageSubscriptionRightContainer">
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <h2 className="AvailableDollarSetting">{daysToFinish} Days</h2>
                <p
                  className="AvailableDollarSetting"
                  style={{ fontSize: "25px", marginLeft: "10px" }}
                >
                  to finish your license
                </p>
              </div>
              <ProgressBar>
                <ProgressBar
                  striped
                  variant="danger"
                  now={(daysToFinish / 30) * 100}
                  key={1}
                />
              </ProgressBar>
              <div
                style={{
                  border: "1px solid rgb(255 255 255 / 34%)",
                  background: "#212529",
                  borderRadius: "6px",
                  width: "100%",
                  padding: "10px 25px",
                }}
                className="AddressBox mt-2"
              >
                <div className="row mt-3">
                  <div
                    className="col-md-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 className="col-md-6 AvailableDollarSetting">Plan</h4>
                    <button
                      onClick={handleEditPlan}
                      style={{
                        width: "40px",
                        height: "40px",
                        padding: "0",
                        background: "white",
                        borderRadius: ".25rem",
                      }}
                      className="col-md-6 cs-btn cs-style1"
                      aria-label="Edit Plan"
                    >
                      <FaPen color="black" style={{ margin: "0" }} />
                    </button>
                  </div>
                </div>
                <div className="row mt-3">
                  <div
                    className="col-md-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 className="col-md-1">
                      <FaFileInvoiceDollar />
                    </h4>
                    <p className="col-md-11 AddressText">{plan || "No Plan Subscribed"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Billing History */}
          {billingHistory && billingHistory.length > 0 && (
            <div className="row mt-4">
              <h4 className="text-white">Billing History</h4>
              <Table
                className="mt-3"
                striped
                bordered
                hover
                responsive
                variant="dark"
              >
                <thead>
                  <tr>
                    <th>Invoice ID</th>
                    <th>Date</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {billingHistory.map((bill, index) => (
                    <tr key={index}>
                      <td>{bill.stripe_invoice_id}</td>
                      <td>{new Date(bill.created_at).toLocaleDateString()}</td>
                      <td>${bill.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
      <PlanSelectionModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onPlanSelect={handlePlanSelect}
        currentPlanId={currentPlanId} // Pass currentPlanId
      />
    </>
  );
};

export default ManageSubscription;
