import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap"; 
import ReferralDashboard from "./ReferralDashboard";
import axiosInstance from "../../api/axiosRest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ReferralProgramModal({
  mode,
  onClose,
  onAcceptTerms,
  currentCredits
}) {
  const [terms, setTerms] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mode === "terms") {
      fetchTerms();
    }
  }, [mode]);

  const fetchTerms = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/referral-terms/");
      setTerms(response.data.content);
    } catch (error) {
      console.error("Error fetching terms:", error);
      toast.error("Failed to load Terms and Conditions.");
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <ToastContainer 
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
    />
      <ToastContainer />
      <Modal show onHide={onClose} centered style={{ 
        '--bs-modal-bg': '#181818',
        borderBottom: "1px solid #ff6600",
        borderRadius: "10px",
        color: "#fff",
         }}>
      {mode === "terms" && (
          <>
            <Modal.Header closeButton style={{ paddingBottom: "0" }}>
              <Modal.Title>Referral Program Terms & Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <div>
                  {terms || "Loading Terms and Conditions..."}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onAcceptTerms}
                style={{
                  backgroundColor: "#8b00ff",
                  border: "none",
                }}
              >
                Accept & Join
              </Button>
            </Modal.Footer>
          </>
        )}

        {mode === "dashboard" && (
          <>
            <Modal.Header closeButton style={{ paddingBottom: "20px" }}>
              <Modal.Title>Referral Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReferralDashboard />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={onClose}>
                Close
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}
