import React from "react";
import Card from "../CreditCards/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./PaymentMethodForm.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PaymentMethodForm = () => {
  const cachedPaymentData = JSON.parse(localStorage.getItem("paymentData")) || {
    number: "",
    name: "",
    expiry: "",
    cvc: "",
  };

  const formik = useFormik({
    initialValues: cachedPaymentData,
    validationSchema: Yup.object({
      number: Yup.string()
        .required("Card number is required")
        .matches(/^[0-9\s]{16,19}$/, "Enter a valid card number"),
      name: Yup.string()
        .required("Name is required")
        .matches(/^[A-Za-z\s]+$/, "Name must contain only letters"),
      expiry: Yup.string()
        .required("Expiry date is required")
        .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, "Enter a valid expiry date (MM/YY)"),
      cvc: Yup.string()
        .required("CVC is required")
        .matches(/^\d{3,4}$/, "Enter a valid CVC"),
    }),
    onSubmit: (values) => {
      localStorage.setItem("paymentData", JSON.stringify(values));
      toast.success("Payment method updated successfully!");
    },
  });

  return (
    <>
          <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    <div className="container mt-5">
      
      <div className="row">
        {/* Credit Card Preview */}
        <div className="col-md-6 mb-4">
          <Card
            number={formik.values.number}
            name={formik.values.name}
            expiry={formik.values.expiry}
            cvc={formik.values.cvc}
            focused={formik.touched.focusedField}
          />
        </div>

        {/* Payment Method Form */}
        <div className="col-md-6">
          <h2 className="mb-4">Update Payment Method</h2>
          <form onSubmit={formik.handleSubmit}>
            {/* Card Number */}
            <div className="form-group mb-3">
              <label htmlFor="number">Card Number</label>
              <input
                type="text"
                id="number"
                name="number"
                className={`cs-form_field ${
                  formik.touched.number && formik.errors.number ? "is-invalid" : ""
                }`}
                placeholder="Card Number"
                value={formik.values.number}
                onChange={formik.handleChange}
                onFocus={() => formik.setFieldTouched("number")}
              />
              {formik.touched.number && formik.errors.number && (
                <div className="invalid-feedback">{formik.errors.number}</div>
              )}
            </div>

            {/* Card Holder Name */}
            <div className="form-group mb-3">
              <label htmlFor="name">Card Holder Name</label>
              <input
                type="text"
                id="name"
                name="name"
                className={`cs-form_field ${
                  formik.touched.name && formik.errors.name ? "is-invalid" : ""
                }`}
                placeholder="Full Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onFocus={() => formik.setFieldTouched("name")}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="invalid-feedback">{formik.errors.name}</div>
              )}
            </div>

            {/* Expiry Date */}
            <div className="form-group mb-3">
              <label htmlFor="expiry">Expiry Date</label>
              <input
                type="text"
                id="expiry"
                name="expiry"
                className={`cs-form_field ${
                  formik.touched.expiry && formik.errors.expiry ? "is-invalid" : ""
                }`}
                placeholder="MM/YY"
                value={formik.values.expiry}
                onChange={formik.handleChange}
                onFocus={() => formik.setFieldTouched("expiry")}
              />
              {formik.touched.expiry && formik.errors.expiry && (
                <div className="invalid-feedback">{formik.errors.expiry}</div>
              )}
            </div>

            {/* CVC */}
            <div className="form-group mb-4">
              <label htmlFor="cvc">CVC</label>
              <input
                type="text"
                id="cvc"
                name="cvc"
                className={`cs-form_field ${
                  formik.touched.cvc && formik.errors.cvc ? "is-invalid" : ""
                }`}
                placeholder="CVC"
                value={formik.values.cvc}
                onChange={formik.handleChange}
                onFocus={() => formik.setFieldTouched("cvc")}
              />
              {formik.touched.cvc && formik.errors.cvc && (
                <div className="invalid-feedback">{formik.errors.cvc}</div>
              )}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="cs-btn cs-style1 mb-4"
              style={{ fontSize: "14px" }}
            >
              Update Payment Method
            </button>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default PaymentMethodForm;
