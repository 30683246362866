import axios from 'axios';

let baseURL = '/graphql';

if (process.env.REACT_APP_MODE === 'production') {
    baseURL = process.env.REACT_APP_PROD;
} else {
    baseURL = process.env.REACT_APP_DEV;
}
export { baseURL };

const axiosInstance = axios.create({
    baseURL: baseURL + '/graphql',
}); 

export default axiosInstance;