import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const AuthOnlyProtectedRoute = () => {
  const { isAuthenticated, isSubscribed, isLoading } = useContext(AuthContext);
  const location = useLocation();

  if (isLoading) return <div>Loading...</div>;

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (isSubscribed) {
    return <Navigate to="/auth" state={{ from: location }} replace />
  }

  return <Outlet />;
};

export default AuthOnlyProtectedRoute;
