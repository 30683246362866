import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Cancel() {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/pricing'); 
    }, 5000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <h1>Subscription Canceled</h1>
      <p>Your subscription process was canceled. Feel free to try again anytime.</p>
      <p>You will be redirected to the pricing page shortly...</p>
    </div>
  );
}
