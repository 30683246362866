import React, { useState } from 'react';
import Section from '../Div'; // your layout component
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// -----------------------------------------------
// Sample data. In a real app, fetch from your API
// -----------------------------------------------
const sportsData = {
  NBA: {
    sidebarOptions: [
      'Opponent Points per Game',
      'Opponent Rebounds per Game',
      'Opponent Assists per Game',
      'HotStreaks NBA',
      'NBA Projections',
    ],
    tables: {
      'Opponent Points per Game': [
        { player: 'LeBron James', points: 29, rebounds: 8 },
        { player: 'Stephen Curry', points: 31, rebounds: 5 },
      ],
      'Opponent Rebounds per Game': [
        { player: 'Nikola Jokić', rebounds: 10 },
        { player: 'Joel Embiid', rebounds: 11 },
      ],
      'Opponent Assists per Game': [
        { player: 'Chris Paul', assists: 11 },
        { player: 'Trae Young', assists: 9 },
      ],
      'HotStreaks NBA': [
        { player: 'Kevin Durant', last5: [28, 30, 34, 29, 35] },
        { player: 'Giannis Antetokounmpo', last5: [32, 27, 33, 36, 31] },
      ],
      'NBA Projections': [
        { player: 'Luka Dončić', projPoints: 30, projAssists: 8 },
        { player: 'Jayson Tatum', projPoints: 27, projRebounds: 7 },
      ],
    },
  },
  MLB: {
    sidebarOptions: [
      'Team Strikeouts per Game',
      'Team Hits per Game',
      'ColdHot Matchup',
      'HotStreaks MLB',
      '1st Inning Runs',
      'MLB Projections',
    ],
    tables: {
      'Team Strikeouts per Game': [
        { team: 'Yankees', strikeouts: 9 },
        { team: 'Red Sox', strikeouts: 8 },
      ],
      'Team Hits per Game': [
        { team: 'Dodgers', hits: 8 },
        { team: 'Mets', hits: 9 },
      ],
      'ColdHot Matchup': [
        { matchup: 'Yankees vs Red Sox', rating: 'Hot' },
        { matchup: 'Cubs vs Cardinals', rating: 'Cold' },
      ],
      'HotStreaks MLB': [
        { player: 'Aaron Judge', homersLast5: [1, 0, 2, 1, 1] },
        { player: 'Mookie Betts', homersLast5: [0, 1, 1, 2, 0] },
      ],
      '1st Inning Runs': [
        { game: 'Giants vs Dodgers', firstInningRuns: 2 },
        { game: 'Astros vs Angels', firstInningRuns: 1 },
      ],
      'MLB Projections': [
        { team: 'Braves', projectedRuns: 5 },
        { team: 'Phillies', projectedRuns: 4 },
      ],
    },
  },
  NFL: {
    sidebarOptions: [
      'HotStreaks NFL',
      'Rushing Yards Allowed',
      'Pass Yards Allowed',
      'NFL Projections',
    ],
    tables: {
      'HotStreaks NFL': [
        { player: 'Patrick Mahomes', last5TDs: [3, 2, 3, 4, 3] },
        { player: 'Josh Allen', last5TDs: [2, 3, 3, 2, 4] },
      ],
      'Rushing Yards Allowed': [
        { team: '49ers', yardsAllowed: 85 },
        { team: 'Ravens', yardsAllowed: 95 },
      ],
      'Pass Yards Allowed': [
        { team: 'Chiefs', passYardsAllowed: 250 },
        { team: 'Bills', passYardsAllowed: 210 },
      ],
      'NFL Projections': [
        { team: 'Cowboys', projectedScore: 24 },
        { team: 'Packers', projectedScore: 20 },
      ],
    },
  },
  EV: {
    sidebarOptions: ['+EV'],
    tables: {
      '+EV': [
        { pick: 'NBA Player Over Points', expectedValue: '+3%' },
        { pick: 'MLB Team Over Runs', expectedValue: '+1.5%' },
        
      ],
    },
  },
};

export default function SportsSidebar() {
  // Top toggles: "NBA", "MLB", "NFL", "EV"
  const [activeTab, setActiveTab] = useState('NBA');
  // Selected sub-option from the sidebar; default to first of current category
  const [selectedOption, setSelectedOption] = useState(
    sportsData['NBA'].sidebarOptions[0]
  );

  // When switching the main category, reset the sidebar sub-option
  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setSelectedOption(sportsData[newTab].sidebarOptions[0]);
  };

  // Data for the currently selected sub-option
  const tableData = sportsData[activeTab].tables[selectedOption] || [];

  // Sidebar styling for a classic look with a dark theme
  const sidebarStyle = {
    backgroundColor: '#2b2b2b',
    minHeight: '100%',
    padding: '0.5rem',
    borderRight: '1px solid #444',
    borderRadius: '5px',
  };

  const sidebarItemStyle = (option) => ({
    padding: '0.6rem 0.8rem',
    marginBottom: '0.2rem',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: selectedOption === option ? '#444' : 'transparent',
    color: '#fff',
    transition: 'background 0.2s',
  });

  const sidebarItemHoverStyle = {
    backgroundColor: '#555',
  };

  return (
    <Section className="position-relative" style={{ minHeight: '50vh', padding: '1rem', backgroundColor: '#121212' }}>
      {/* ------------------ TOP TOGGLES (same as your previous design) ------------------ */}
      <Section style={{ width: '100%', textAlign: 'center'}}>
        <ul className="cs-tab_links cs-style1 cs-primary_font" style={{ position: 'relative', top: '0'}}>
          {['NBA', 'MLB', 'NFL', 'EV'].map((sport, index) => (
            <li
              key={index}
              className={activeTab === sport ? 'active mr-3' : 'mr-3'}
              onClick={() => handleTabChange(sport)}
              style={{ cursor: 'pointer' }}
            >
              {sport === 'EV' ? '+EV' : sport}
            </li>
          ))}
        </ul>
      </Section>

      {/* ------------------ LAYOUT: LEFT SIDEBAR + RIGHT TABLE ------------------ */}
      <Section className="row" style={{ backgroundColor: '#1c1c1c', padding: '1rem', borderRadius: '8px' }}>
        {/* Left Sidebar */}
        <Section className="col-lg-3" style={sidebarStyle}>
        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          {sportsData[activeTab].sidebarOptions.map((option, index) => (
            <li
              key={index}
              style={sidebarItemStyle(option)}
              onClick={() => setSelectedOption(option)}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = selectedOption === option ? '#444' : '#555';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = selectedOption === option ? '#444' : 'transparent';
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      </Section>

        {/* Right Content: Table */}
        <Section className="col-lg-9" style={{ paddingLeft: '1rem' }}>
          <h3 style={{ marginBottom: '1rem', color: '#f1f1f1' }}>
            {activeTab} &gt; {selectedOption}
          </h3>

          <DataTable
            value={tableData}
            // paginator
            rows={5}
            responsiveLayout="scroll"
            className="p-datatable-striped p-datatable-gridlines"
            style={{ backgroundColor: '#1c1c1c', color: '#fff' }}
          >
            {tableData.length > 0 ? (
              // Dynamically render columns from object keys
              Object.keys(tableData[0]).map((key) => (
                <Column key={key} field={key} header={key.toUpperCase()} />
              ))
            ) : (
              // Fallback if no data is available
              <Column field="" header="" body={() => 'No data available'} />
            )}
          </DataTable>
        </Section>
      </Section>
    </Section>
  );
}
