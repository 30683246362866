// App.js
import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import AboutPage from "./Pages/AboutPage";
import AuthPage from "./Pages/AuthPage.jsx";
import ContactPage from "./Pages/ContactPage";
import ErrorPage from "./Pages/ErrorPage";
import Home from "./Pages/Home";
import ServicesPage from "./Pages/ServicesPage";
import PortfolioPage from "./Pages/PortfolioPage";
import Layout from "./components/Layout";
import FaqPage from "./Pages/FaqPage";
import DataTable from "./Pages/auth/DataTable.jsx";
import { DataContext } from "./context/DataContext";
import HomePage from "./Pages/auth/HomePage.jsx";
import Profile from "./Pages/auth/Profile";
import ChangePassword from "./Pages/auth/ChangePassword";
import PasswordResetConfirm from "./components/Password/PasswordResetConfirm.jsx";
import PasswordResetRequest from "./components/Password/PasswordResetRequest.jsx"
import BillingHistory from "./Pages/auth/BillingHistory";
import ManageSubscription from "./Pages/auth/ManageSubscription";
import AuthLayout from "./components/AuthLayout";
import PrivateRoute from "./PrivateRoute.js";
import PublicRoute from "./PublicRoute.js";
import LoginRedirect from "./components/LoginRedirect";
import PaymentMethodForm from "./components/PaymentMethod/PaymentMethodForm.jsx";
import TestCreditCard from "./components/CreditCards/TestCreditCard.jsx";
import ProtectedRoute from "./Route/ProtectedRoute.jsx";
import AuthOnlyProtectedRoute from "./Route/AuthOnlyProtectedRoute.jsx"
import Success from './components/Success/index.jsx';
import Cancel from './components/Cancel/index.jsx';

function App() {
  const data = useContext(DataContext) || {};

  // Configurations for MLB
  const mlbConfigs = {
    opponentstrikeout: {
      displayName: "Team Strikeouts per Game",
      data: data.strikeoutsPerGame || [],
      titleText:
      "Displays the average total strikeouts per game by each team in the MLB",
    },
    opponenthit: {
      displayName: "Team Hits per Game",
      data: data.hitsPerGame || [],
      titleText:
      "Displays the average total hits per game by each team in the MLB",
    },
    BaseballMatchupStats: {
      displayName: "ColdHot Matchup",
      data: data.baseballMatchupStats || [],
      titleText:
      "Displays which hitters have a hot matchup vs todays opposing pitcher and which hitters have a cold matchup vs todays opposing pitcher. Based on previous career matchups.",
    },
    HotStreaks_MLB: {
      displayName: "HotStreaks MLB",
      data: data.hotStreaksMLB || [],
      titleText:
        "Collects and displays player props that are currently on streaks hitting their over/under consistently in their most recent games.",
    },
    innings: {
      displayName: "1st Inning Runs",
      data: data.iningsRuns || [],
      titleText:
      "Displays each teams average runs scored in the first inning at home and away. Specifically for the runs first inning betting prop.",
    },
    mlbProjections: {
      displayName: "MLB Projections",
      data: data.playerPropsMLB || [],
      titleText:
        "The MLB projections model uses a mathematical equation to predict the outcome of player props.",
    },
  };

  // Configurations for NBA
  const nbaConfigs = {
    pointsPerGame: {
      displayName: "Opponent Points per Game",
      data: data.pointsPerGame || [],
      titleText:
      "Displays the average total points ALLOWED per game by each team in the NBA",
    },
    reboundsPerGame: {
      displayName: "Opponent Rebounds per Game",
      data: data.reboundsPerGame || [],
      titleText:
      "Displays the average total rebounds ALLOWED per game by each team in the NBA",
    },
    assistsPerGame: {
      displayName: "Opponent Assists per Game",
      data: data.assistsPerGame || [],
      titleText:
      "Displays the average total assist ALLOWED per game by each team in the NBA",
    },
    HotStreaks_NBA: {
      displayName: "HotStreaks NBA",
      data: data.hotStreaksNBA || [],
      titleText:
        "Collects and displays player props that are currently on streaks hitting their over/under consistently in their most recent games.",
    },
    nbaProjections: {
      displayName: "NBA Projections",
      data: data.playerPropsNBA || [],
      titleText:
        "",
    },
  };

  // Configurations for NFL
  const nflConfigs = {
    Yard: {
      displayName: "HotStreaks NFL",
      data: data.hotStreaksNFL || [],
      titleText:
        "Collects and displays player props that are currently on streaks hitting their over/under consistently in their most recent games.",
    },
    RushingYards: {
      displayName: "Rushing Yards Allowed",
      data: data.pointsPerGame || [],
      titleText:
      "Displays the average total rushing yards ALLOWED per game by each team in the NFL",
    },
    PassingYards: {
      displayName: "Pass Yards Allowed",
      data: data.reboundsPerGame || [],
      titleText:
      "Displays the average total passing yards ALLOWED per game by each team in the NFL",
    },
    nflProjections: {
      displayName: "NFL Projections",
      data: data.playerPropsNFL || [],
      titleText:
        "",
    },
  };

  // Configurations for +EV
  const evConfigs = {
    GET_PLAYER_STATS: {
      displayName: "+EV",
      data: data.playerStats || [],
      titleText:
        "Collects and displays player prop odds from DraftKings to take advantage of PrizePicks and Underdog's set payout system. The +EV Model shows you which player props are most favored by sportsbooks so you’re always betting on the favorite when the payout odds are set.",
    },
  };

  return (
    <>
      <Routes>
        {/* <Route path="/login-fake" element={<LoginRedirect />} /> */}
        {/* Public Routes */}
        <Route path="/" element={<PublicRoute><Layout /></PublicRoute>}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="pricing" element={<ServicesPage />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="login" element={<AuthPage />} />
          <Route path="register" element={<AuthPage />} />
          <Route path="/password-reset" element={<PasswordResetRequest />} />
          <Route path="/password-reset-confirm" element={<PasswordResetConfirm />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="faq" element={<FaqPage />} />
        </Route>

        {/* Auth-only route (for subscribe page) */}
        <Route element={<AuthOnlyProtectedRoute />}>
          <Route path="/subscribe" element={<ServicesPage />} />
          {/* <Route path="/checkout" element={<Checkout />} /> */}
          <Route path="/subscription-success" element={<Success />} />
          <Route path="/subscription-cancel" element={<Cancel />} />
        </Route>

        {/* Authenticated and Subscribed Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/auth" element={<AuthLayout />}>
            {/* NBA Routes */}
            <Route
              path="nba"
              element={
                <DataTable
                  configs={nbaConfigs}
                />
              }
            />
            <Route
              path="nba/points-per-game"
              element={
                <DataTable
                  configs={{ pointsPerGame: nbaConfigs.pointsPerGame }}
                  colors={["#31df31", "yellow", "red"]}
                />
              }
            />
            <Route
              path="nba/rebounds-per-game"
              element={
                <DataTable
                  configs={{ reboundsPerGame: nbaConfigs.reboundsPerGame }}
                  colors={["#31df31", "yellow", "red"]}
                />
              }
            />
            <Route
              path="nba/assist-per-game"
              element={
                <DataTable
                  configs={{ assistsPerGame: nbaConfigs.assistsPerGame }}
                  colors={["#31df31", "yellow", "red"]}
                />
              }
            />
            <Route
              path="nba/hotstreaks-nba"
              element={
                <DataTable
                  configs={{ HotStreaks_NBA: nbaConfigs.HotStreaks_NBA }}
                />
              }
            />
            <Route
              path="nba/nba-projections"
              element={
                <DataTable
                  configs={{ nbaProjections: nbaConfigs.nbaProjections }}
                />
              }
            />

            {/* MLB Routes */}
            <Route
              path="mlb"
              element={
                <DataTable
                  configs={mlbConfigs}
                />
              }
            />
            <Route
              path="mlb/strikeouts"
              element={
                <DataTable
                  configs={{ opponentstrikeout: mlbConfigs.opponentstrikeout }}
                  colors={["#31df31", "yellow", "red"]}
                />
              }
            />
            <Route
              path="mlb/hits"
              element={
                <DataTable
                  configs={{ opponenthit: mlbConfigs.opponenthit }}
                  colors={["#31df31", "yellow", "red"]}
                />
              }
            />
            <Route
              path="mlb/coldhot-matchup"
              element={
                <DataTable
                  configs={{ BaseballMatchupStats: mlbConfigs.BaseballMatchupStats }}
                />
              }
            />
            <Route
              path="mlb/hotstreaks-mlb"
              element={
                <DataTable
                  configs={{ HotStreaks_MLB: mlbConfigs.HotStreaks_MLB }}
                />
              }
            />
            <Route
              path="mlb/first-inning-runs"
              element={
                <DataTable
                  configs={{ innings: mlbConfigs.innings }}
                  colors={["#31df31", "yellow", "red"]}
                />
              }
            />
            <Route
              path="mlb/mlb-projections"
              element={
                <DataTable
                  configs={{ mlbProjections: mlbConfigs.mlbProjections }}
                />
              }
            />

            {/* NFL Routes */}
            <Route
              path="nfl"
              element={
                <DataTable
                  configs={nflConfigs}
                />
              }
            />
            <Route
              path="nfl/hotstreaks-nfl"
              element={
                <DataTable
                  configs={{ Yard: nflConfigs.Yard }}
                />
              }
            />
            <Route
              path="nfl/rushing-yards-allowed"
              element={
                <DataTable
                  configs={{ RushingYards: nflConfigs.RushingYards }}
                  colors={["#31df31", "yellow", "red"]}
                />
              }
            />
            <Route
              path="nfl/pass-yards-allowed"
              element={
                <DataTable
                  configs={{ PassingYards: nflConfigs.PassingYards }}
                  colors={["#31df31", "yellow", "red"]}
                />
              }
            />
            <Route
              path="nfl/nfl-projections"
              element={
                <DataTable
                  configs={{ nflProjections: nflConfigs.nflProjections }}
                />
              }
            />

            {/* +EV Routes */}
            <Route
              path="ev"
              element={
                <DataTable
                  configs={evConfigs}
                />
              }
            />
            <Route
              path="ev/ev"
              element={
                <DataTable
                  configs={{ GET_PLAYER_STATS: evConfigs.GET_PLAYER_STATS }}
                />
              }
            />

            {/* User Options */} 
            <Route index element={<HomePage />} />
            <Route path="profile" element={<Profile />} />
            <Route path="ChangePassword" element={<ChangePassword />} />
            <Route path="billing-history" element={<BillingHistory />} />
            <Route path="manage-subscription" element={<ManageSubscription />} />
            <Route path="payment-method" element={<PaymentMethodForm />} />
          </Route>
        </Route>
        {/* <Route path="/test-credit-card" element={<TestCreditCard />} /> */}
        {/* Fallback Route */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
