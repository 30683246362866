import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import './pricing.scss';
import Button from '../Button';
import { AuthContext } from '../../context/AuthContext';
import axiosInstance from '../../api/axiosRest';
import { PAYMENT, SUBSCRIPTION } from '../../api/urls';
import Div from '../Div';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


export default function PricingTable({ title, price, currency, features, btnText, timeline, tab, price_id, discounted }) {
  const { isAuthenticated } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handlePurchase = async () => {
    if (isAuthenticated) {
      setIsLoading(true);
      const currentUrl = window.location.href;
      const payload = {
        price_id,
      };
  
      try {
        let response;
        if (currentUrl.includes('auth/manage-subscription')) {
          // Use PAYMENT.CHANGE_PLAN URL
          response = await axiosInstance.post(PAYMENT.CHANGE_PLAN, payload);
          if (response.status === 200) {
            toast.success('Subscription plan updated successfully.');
          } else {
            toast.error('Failed to update subscription plan. Please try again later.');
          }
        } else {
          // Use PAYMENT.CREATE_CHECKOUT_SESSION URL
          payload.success_url = `${currentUrl}/subscription-success?session_id={CHECKOUT_SESSION_ID}`;
          payload.cancel_url = `${currentUrl}/subscription-cancel`;
          response = await axiosInstance.post(PAYMENT.CREATE_CHECKOUT_SESSION, payload);
  
          const { sessionId } = response.data;
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
          await stripe.redirectToCheckout({ sessionId });
        }
      } catch (error) {
        console.error('Error processing request:', error);
        toast.error('Failed to initiate subscription. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    } else {
      navigate('/login');
    }
  };


  return (
    <>
    <ToastContainer 
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
<Div className="cs-pricing_table cs-style1">
  <h2 className="cs-pricing_title">
    {title}
    {discounted && (
      <Div className="cs-discount-box">
        <span>20% Discount Applied</span>
      </Div>
    )}
  </h2>
  <Div className="cs-pricing_info">
    <Div className="cs-price">
      <h3 className="cs-white_color">
        {currency}
        {price}/
      </h3>
      <span className="cs-accent_color">{timeline}</span>
    </Div>
    <Div className="cs-price_text">
      Per subscriber per {timeline === 'monthly' ? 'month' : 'year'}
    </Div>
  </Div>
  <ul className="cs-pricing_feature cs-mp0">
    {features.map((feature, index) => (
      <li key={index}>
        <span className="cs-feature_icon cs-accent_color">
          <Icon icon="bi:arrow-right-circle" />
        </span>
        <span>{feature}</span>
      </li>
    ))}
  </ul>
  <Div className="cs-pricing_btn_wrap" style={{ textAlign: 'center' }}>
    {isLoading ? (
      <div className="d-flex justify-content-center">
        <Icon icon="bi:spinner" className="animate-spin" />
      </div>
    ) : (
      <Button onClick={handlePurchase} btnText={btnText} />
    )}
  </Div>
</Div>

    </>
  );
}

PricingTable.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  currency: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  btnText: PropTypes.string.isRequired,
  timeline: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  price_id: PropTypes.string.isRequired, 
};