import React from "react";
import Div from "../../components/Div";
import SectionHeading from "../../components/SectionHeading";
import Spacing from "../../components/Spacing";
import Post from "../../components/Post";

const postData = [
  {
    url: "/auth/nba",
    src: "/images/NBAauth.png",
    alt: "Post",
    title: "NBA",
  },
  {
    url: "/auth/mlb",
    src: "/images/MLB.jpg",
    alt: "Post",
    title: "MLB",
  },
  {
    url: "/auth/ev",
    src: "/images/EVauth.png",
    alt: "Post",
    title: "EV",
  },
  {
    url: "/auth/nfl",
    src: "/images/NFLauth.png",
    alt: "Post",
    title: "NFL",
  },
];
const settings = {
  dots: false,
  arrows: false,
  infinite: true, // Consider setting this to false if you don't want looping
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 1000,
  slidesToShow: 4, // Adjusted to show only 2 slides
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

const HomePage = () => {
  return (
    <>
      <Spacing lg="80" md="80" />
      <div className="cs-shape_wrap_4" style={{ marginBottom: "30px" }}>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore More Statistics"
                subtitle="Statistics"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8 ">
              <div className="authMainContainer">
                {postData.map((item, index) => (
                  <Div key={index}>
                    <a href={item.url}>
                      <Post
                        url={item.url}
                        src={item.src}
                        alt={item.alt}
                        date={item.date}
                        title={item.title}
                      />
                    </a>
                  </Div>
                ))}
              </div>
            </Div>
          </Div>
        </Div>
      </div>
    </>
  );
};

export default HomePage;
