// src/pages/Profile/Profile.jsx

import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axiosRest"; // Use the custom Axios instance
import Spacing from "../../components/Spacing";
import Div from "../../components/Div"; // Assuming Div is a custom component
import PageHeading from "../../components/PageHeading"; // Optional: If you have a heading component
import { useNavigate } from "react-router-dom";
import { PROFILE } from "../../api/urls"; // Importing PROFILE URLs
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Icon } from "@iconify/react"; // Ensure Icon is imported if used in buttons

const Profile = () => {
  const [userData, setUserData] = useState({
    username: "",
    phone: "",
    address: "",
    email: "",
    profile_image: null,
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  // Removed 'error' state variable

  const navigate = useNavigate();

  // Fetch user profile data on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axiosInstance.get(PROFILE.GET_UPDATE);
        setUserData({
          username: response.data.username,
          phone: response.data.phone || "",
          address: response.data.address || "",
          email: response.data.email || "",
          profile_image: response.data.profile_image || null,
        });
        setPreviewImage(response.data.profile_image || null);
      } catch (err) {
        console.error("Error fetching profile data:", err);
        toast.error("Failed to load profile data.");
      }
    };

    fetchProfile();
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Removed setError
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  // Handle image deletion
  const handleDeleteImage = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.delete(PROFILE.DELETE_IMAGE);
      if (response.status === 200) {
        setUserData((prevData) => ({
          ...prevData,
          profile_image: null,
        }));
        setPreviewImage(null);
        setSelectedImage(null);
        toast.success("Profile image deleted successfully.");
      }
    } catch (err) {
      console.error("Error deleting profile image:", err);
      toast.error("Failed to delete profile image.");
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Removed setError

    try {
      const formData = new FormData();
      formData.append("username", userData.username);
      formData.append("phone", userData.phone);
      formData.append("address", userData.address);
      if (selectedImage) {
        formData.append("profile_image", selectedImage);
      }

      const response = await axiosInstance.put(PROFILE.GET_UPDATE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setUserData({
        username: response.data.username,
        phone: response.data.phone || "",
        address: response.data.address || "",
        email: response.data.email || "",
        profile_image: response.data.profile_image || null,
      });
      setPreviewImage(response.data.profile_image || null);
      setSelectedImage(null);
      toast.success("Profile updated successfully.");
    } catch (err) {
      console.error("Error updating profile:", err);
      if (err.response?.data) {
        const errors = Object.values(err.response.data).flat();
        toast.error(errors.join(" "));
      } else {
        toast.error("Failed to update profile.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Determine the correct image URL
  const getImageUrl = (url) => {
    if (!url) return "/images/avatar.webp"; 
    if (url.startsWith("http")) return url;
    return `${process.env.REACT_APP_API_URL}${url}`;
  };

  return (
    <>
      {/* Toast Container */}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <PageHeading
        title="Profile Settings"
        bgSrc="/images/profile_hero_bg.jpeg"
        pageLinkText="Profile"
        auth="/auth"
      />
      {/* <Spacing lg="70" md="0" /> */}
      <div className="ProfileContainer">
        <div className="row">
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="row d-flex ProfileImageContainer mb-4" style={{ paddingRight: "0" }}>
                <div className="col-md-7 d-flex ProfileImage" style={{ justifyContent: "end" }}>
                  <div
                    style={{
                      height: "150px",
                      width: "150px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      boxShadow: "#FF4A17 0px 0px 10px 2px",
                    }}
                  >
                    <img
                      src={getImageUrl(previewImage)}
                      alt="Profile"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                {/* Uncomment and update the following section if you want to enable image upload and deletion */}
                {/* 
                <div className="col-md-6 ProfileBtnContainer d-flex flex-column">
                  <label
                    htmlFor="profileImage"
                    className="cs-btn cs-style1 mt-2 ProfileBtn"
                    style={{ cursor: "pointer" }}
                  >
                    <span>Change Picture</span>
                    <input
                      type="file"
                      id="profileImage"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                  </label>
                  {previewImage && (
                    <button
                      type="button"
                      onClick={handleDeleteImage}
                      className="cs-btn cs-style1 mt-2 ProfileBtn"
                      style={{ background: "#fff", color: "#000", marginTop: "10px" }}
                      disabled={loading}
                    >
                      <span>Delete Picture</span>
                      <Icon icon="bi:trash" /> {/* Example Icon for Delete */}
                    {/* </button>
                  )}
                </div> 
                */}
              </div>
            </div>
            <form
              className="ProfileFormContainer"
              style={{ color: "white" }}
              onSubmit={handleSubmit}
            >
              {/* Removed Error Message Div */}
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="username" className="mb-1">
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    value={userData.username}
                    onChange={handleChange}
                    placeholder="Enter Username"
                    className="cs-form_field"
                    style={{ borderRadius: ".25rem" }}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="phone" className="mb-1">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={userData.phone}
                    onChange={handleChange}
                    placeholder="Enter Phone Number"
                    className="cs-form_field"
                    style={{ borderRadius: ".25rem" }}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="email" className="mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={userData.email}
                    readOnly
                    placeholder="Enter Email"
                    className="cs-form_field"
                    style={{ borderRadius: ".25rem", backgroundColor: "#6c757d" }} // Gray background to indicate read-only
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="address" className="mb-1">
                    Address
                  </label>
                  <textarea
                    name="address"
                    id="address"
                    value={userData.address}
                    onChange={handleChange}
                    placeholder="Enter your address"
                    className="cs-form_field"
                    style={{ borderRadius: ".25rem" }}
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <br />
              <button
                type="submit"
                style={{ width: "100%", borderRadius: ".25rem" }}
                className="cs-btn cs-style1 mb-4"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
                <Icon icon="bi:arrow-right" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
