import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {baseURL} from './axios';

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

console.log(baseURL);
const httpLink = createHttpLink({
  uri: baseURL + '/graphql/',
  credentials: 'include',
});

const csrfLink = setContext((_, { headers }) => {
  const csrfToken = getCookie('csrftoken');
  return {
    headers: {
      ...headers,
      'X-CSRFToken': csrfToken,
    },
  };
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: csrfLink.concat(httpLink),
  cache,
});


export default client;