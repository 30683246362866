import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { DataProvider } from "../context/DataContext";
import { ProgressSpinner } from "primereact/progressspinner";

const ProtectedRoute = () => {
  const { isAuthenticated, isSubscribed, isLoading } = useContext(AuthContext);
  const location = useLocation();

  if (isLoading) {
  return  (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
    </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isSubscribed && location.pathname !== '/subscribe') {
    return <Navigate to="/subscribe" state={{ from: location }} replace />;
  }

  return (
    <DataProvider>
      <Outlet />
    </DataProvider>
  );
};

export default ProtectedRoute;
