import React, { useEffect } from 'react'
import { pageTitle } from '../helper'
import PageHeading from '../components/PageHeading'
import PricingTableList from '../components/PricingTable/PricingTableList'
import Div from '../components/Div'
import SectionHeading from '../components/SectionHeading'
import Spacing from '../components/Spacing'

export default function ServicesPage() {
  pageTitle('Pricing');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Pricing'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='Pricing'
      />
    
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Spacing lg='85' md='60'/>
        <PricingTableList/>
      </Div>
    
    </>
  )
}
