import React, { useState } from "react";
import { pageTitle } from "../../helper";
import PageHeading from "../../components/PageHeading";
import Div from "../../components/Div";
import Spacing from "../../components/Spacing";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axiosRest";
import { AUTH } from "../../api/urls";
import { Icon } from "@iconify/react";

export default function PasswordResetRequest() {
  pageTitle("Forgot Password");

  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!email) {
      setError("Please enter your email address.");
      return;
    }

    try {
      const response = await axiosInstance.post(
        AUTH.PASSWORD_RESET,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSuccessMessage(response.data.success);
      setEmail("");
    } catch (err) {
      console.error(err.response);
      if (err.response && err.response.data) {
        const errors = Object.values(err.response.data).flat();
        setError(errors.join(" "));
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      <PageHeading
        title="Forgot Password"
        bgSrc="/images/blog_hero_bg.jpeg"
        pageLinkText="Forgot Password"
      />
      <Spacing lg="100" md="70" />
      <Div className="container">
        <Div className="row justify-content-center">
          <Div className="col-lg-6">
            <Div className="password-reset-form">
              <form onSubmit={handleSubmit}>
                <Div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="cs-form_field"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your registered email"
                    required
                  />
                </Div>
                {error && (
                  <Div className="alert alert-danger" role="alert">
                    {error}
                  </Div>
                )}
                {successMessage && (
                  <Div className="alert alert-success" role="alert">
                    {successMessage}
                  </Div>
                )}
                <Div className="d-grid gap-2">
                  <button type="submit" className="cs-btn cs-style1">
                    <span>Send Reset Link</span>
                    <Icon icon="bi:arrow-right" />
                  </button>
                </Div>
              </form>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="50" md="30" />
    </>
  );
}
