import React, { useState } from 'react';
import PricingTable from '.'; // Ensure correct import path
import Section from '../Div';
import Spacing from '../Spacing';
import useFetchPlans from '../../hooks/useFetchPlans';
import { ProgressSpinner } from 'primereact/progressspinner';

export default function PricingTableList({ excludePlanId }) {
  const [tab, setTab] = useState('monthly');
  const { plans, loading, error } = useFetchPlans();

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '200px' }}
      >
        <ProgressSpinner
          style={{ width: '50px', height: '50px' }}
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center">
        Failed to load plans. Please try again later.
      </div>
    );
  }

  const filteredPlans = plans.filter((plan) => {
    if (excludePlanId && plan.id === excludePlanId) {
      return false;
    }

    if (excludePlanId) {
      return true;
    }

    if (tab === 'monthly') {
      return (
        plan.name.toLowerCase() === 'standard' ||
        plan.name.toLowerCase() === 'monthly'
      );
    } else if (tab === 'yearly') {
      return plan.name.toLowerCase() === 'yearly';
    }

    // Default case
    return false;
  });


  return (
    <Section className="position-relative">
      {!excludePlanId && (
        <Section className="d-flex justify-content-center mb-4">
          <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font d-flex">
            <li
              className={tab === 'monthly' ? 'active mr-3' : 'mr-3'}
              onClick={() => setTab('monthly')}
              style={{ cursor: 'pointer' }}
            >
              Monthly
            </li>
            <li
              className={tab === 'yearly' ? 'active' : ''}
              onClick={() => setTab('yearly')}
              style={{ cursor: 'pointer' }}
            >
              Yearly
            </li>
          </ul>
        </Section>
      )}
      <Section className="row" style={{ marginTop: '-10%' }}>
        <Section className={`${excludePlanId ? '' : 'col-lg-4 mx-auto'}`}>
          {filteredPlans.length > 0 ? (
            filteredPlans.map((plan) => (
              <PricingTable
                key={plan.id}
                title={plan.description}
                price={plan.price}
                currency="$"
                timeline={!excludePlanId ? tab : undefined}
                features={Object.values(plan.features)}
                btnText="Purchase Now"
                price_id={plan.stripe_price_id}
                discounted={plan.is_discounted}
              />
            ))
          ) : (
            <p className="text-center">No available plans to subscribe.</p>
          )}
          <Spacing lg="25" md="25" />
        </Section>
      </Section>
    </Section>
  );
}
