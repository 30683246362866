import React, { useState, useEffect } from "react";
import { Form, Button, Table } from "react-bootstrap";
import axiosInstance from "../../api/axiosRest";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ReferralDashboard() {
  const [referralLink, setReferralLink] = useState("");
  const [referrals, setReferrals] = useState([]);
  const [code, setCode] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        // Example: GET /referrals
        const referralRes = await axiosInstance.get("/referrals/");
        setReferrals(referralRes.data);

        // Example: GET /generate-referral-code for single code or existing code
        const codeRes = await axiosInstance.get("/generate-referral-code/");
        setCode(codeRes.data.referral_code);

        // Construct the full referral link
        const link = `${window.location.origin}/register?referral=${codeRes.data.referral_code}`;
        setReferralLink(link);
      } catch (err) {
        console.error("Error loading referral data", err);
      }
    }
    fetchData();
  }, []);

  const copyReferralLink = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      toast.success("Referral link copied!");
    } catch (err) {
      toast.error("Failed to copy referral link.");
    }
  };

  const copyReferralCode = async () => {
    try {
      await navigator.clipboard.writeText(code);
      toast.success("Referral code copied!");
    } catch (err) {
      toast.error("Failed to copy referral code.");
    }
  };

  return (
    <div>
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Form.Group>
        <Form.Label>Referral Link</Form.Label>
        <div style={{ display: "flex" }}>
          <Form.Control type="text" readOnly value={referralLink} className="cs-form_field" />
          <Button onClick={copyReferralLink} style={{ marginLeft: 8 }} className="cs-btn cs-style1">
            Copy Link
          </Button>
        </div>
      </Form.Group>

      <Form.Group className="mt-3">
        <Form.Label>Referral Code</Form.Label>
        <div style={{ display: "flex" }}>
          <Form.Control type="text" readOnly value={code} className="cs-form_field"/>
          <Button onClick={copyReferralCode} style={{ marginLeft: 8 }} className="cs-btn cs-style1">
            Copy Code
          </Button>
        </div>
      </Form.Group>

      <h5 className="mt-4">Your Referrals</h5>
      <Table striped bordered hover responsive variant="dark" className="mt-3">
        <thead>
          <tr>
            <th>Referee Username</th>
            <th>Status</th>
            <th>Used At</th>
          </tr>
        </thead>
        <tbody>
          {referrals.length === 0 && (
            <tr>
              <td colSpan={3}>No referrals yet.</td>
            </tr>
          )}
          {referrals.map((r) => (
            <tr key={r.id}>
              <td>{r.referee_username || "N/A"}</td>
              <td>{r.is_used ? "Used" : "Unused"}</td>
              <td>{r.used_at ? new Date(r.used_at).toLocaleString() : "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
