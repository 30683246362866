const images = {
    visa: require("./visa.png"), // Replace with your image file paths
    mastercard: require("./mastercard.png"),
    amex: require("./amex.png"),
    discover: require("./discover.png"),
    dinersclub: require("./diners-club.png"),
    jcb: require("./jcb.png"),
    unionpay: require("./unionpay.png"),
    // hipercard: require("./hipercard.png"),
    unknown: require("./6.jpeg"), 
  };
  
  export default images;
  