// src/components/PlanModal/PlanSelectionModal.jsx
import React from 'react';
import Modal from "react-modal";
import PricingTableList from '../PricingTable/PricingTableList'
import { FaTimes } from "react-icons/fa";
import { Button } from 'primereact/button'; // Using PrimeReact Button for consistency
import './PlanSelectionModal.scss'; // Ensure styles are correctly imported

Modal.setAppElement("#root"); // Accessibility

const PlanSelectionModal = ({ isOpen, onRequestClose, onPlanSelect, currentPlanId }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Select a Plan"
      className="PlanModal"
      overlayClassName="PlanModalOverlay"
    >
      <div className="modal-header">
        <h3>Select a Subscription Plan</h3>
        <Button   
          icon="pi pi-times" 
          className="p-button-rounded p-button-text close-button" 
          onClick={onRequestClose} 
          aria-label="Close Modal"
        />
      </div>
      <div className="modal-content">
        <PricingTableList excludePlanId={currentPlanId} />
      </div>
    </Modal>
  );
};

export default PlanSelectionModal;
