// src/pages/PasswordResetConfirm.jsx

import React, { useState, useEffect } from "react";
import { pageTitle } from "../../helper";
import PageHeading from "../../components/PageHeading";
import Div from "../../components/Div";
import Spacing from "../../components/Spacing";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../../api/axiosRest";
import { AUTH } from "../../api/urls";
import { Icon } from "@iconify/react";
import zxcvbn from "zxcvbn"; 

export default function PasswordResetConfirm() {
  pageTitle("Reset Password");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Extract uid and token from query parameters
  const query = new URLSearchParams(location.search);
  const uid = query.get("uid");
  const token = query.get("token");

  useEffect(() => {
    if (!uid || !token) {
      setError("Invalid password reset link.");
    }
  }, [uid, token]);

  const handleNewPasswordChange = (value) => {
    setNewPassword(value);
    const evaluation = zxcvbn(value);
    setPasswordStrength(evaluation.score); // score ranges from 0 to 4
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!newPassword || !confirmPassword) {
      setError("Please fill in all fields.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (newPassword.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    // Optional: Add more password strength checks here

    try {
      const response = await axiosInstance.post(
        AUTH.PASSWORD_RESET_CONFIRM,
        { uid, token, new_password: newPassword },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setSuccessMessage(response.data.success);
      setNewPassword("");
      setConfirmPassword("");

      // Optionally, redirect to login after a delay
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (err) {
      console.error(err.response);
      if (err.response && err.response.data) {
        const errors = Object.values(err.response.data).flat();
        setError(errors.join(" "));
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      <PageHeading
        title="Reset Password"
        bgSrc="/images/blog_hero_bg.jpeg"
        pageLinkText="Reset Password"
      />
      <Spacing lg="100" md="70" />
      <Div className="container">
        <Div className="row justify-content-center">
          <Div className="col-lg-6">
            <Div className="password-reset-confirm-form">
              <form onSubmit={handleSubmit}>
                <Div className="mb-3">
                  <label htmlFor="newPassword" className="form-label">
                    New Password
                  </label>
                  <input
                    type="password"
                    id="newPassword"
                    className="cs-form_field"
                    value={newPassword}
                    onChange={(e) => handleNewPasswordChange(e.target.value)}
                    placeholder="Enter new password"
                    required
                  />
                  {/* Password Strength Meter */}
                  <div className="password-strength-meter">
                    <progress
                      className={`password-strength-meter-progress strength-${passwordStrength}`}
                      value={passwordStrength}
                      max="4"
                    />
                    <br />
                    <small>
                      {passwordStrength === 0 && "Very Weak"}
                      {passwordStrength === 1 && "Weak"}
                      {passwordStrength === 2 && "Fair"}
                      {passwordStrength === 3 && "Good"}
                      {passwordStrength === 4 && "Strong"}
                    </small>
                  </div>
                </Div>
                <Div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    className="cs-form_field"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm new password"
                    required
                  />
                </Div>
                {error && (
                  <Div className="alert alert-danger" role="alert">
                    {error}
                  </Div>
                )}
                {successMessage && (
                  <Div className="alert alert-success" role="alert">
                    {successMessage}
                  </Div>
                )}
                <Div className="d-grid gap-2">
                  <button type="submit" className="cs-btn cs-style1">
                    <span>Reset Password</span>
                    <Icon icon="bi:arrow-right" />
                  </button>
                </Div>
              </form>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="50" md="30" />
    </>
  );
}
