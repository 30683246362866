// src/components/Sidebar.jsx

import React, { useState, useEffect, useRef } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Div from "../Div";
import {
  FaBars,
  FaChevronDown,
  FaChevronRight,
  FaLock,
  FaPen,
  FaRegClock,
  FaUser,
} from "react-icons/fa";
import Spacing from "../Spacing";
import "./styles.scss";
import { CSSTransition } from "react-transition-group";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReferralProgramButton from "../Referral/ReferralProgramButton";

export default function Sidebar({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const [selectedSubItemTitle, setSelectedSubItemTitle] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const isLgUp = useMediaQuery({ minWidth: 992 });
  const isMdDown = useMediaQuery({ maxWidth: 991 });
  const isSmDown = useMediaQuery({ maxWidth: 550 });

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  // Create refs for the dropdown and profile image
  const dropdownRef = useRef(null);
  const profileImgRef = useRef(null);

  // Restore your original sidebar configuration here
  const sidebarConfig = [
    {
      title: "NBA",
      route: "/auth/nba",
      subItems: [
        {
          title: "Opponent Points per Game",
          route: "/auth/nba/points-per-game",
        },
        {
          title: "Opponent Rebounds per Game",
          route: "/auth/nba/rebounds-per-game",
        },
        {
          title: "Opponent Assist per Game",
          route: "/auth/nba/assist-per-game",
        },
        { title: "HotStreaks NBA", route: "/auth/nba/hotstreaks-nba" },
        { title: "NBA Projections", route: "/auth/nba/nba-projections" },
      ],
    },
    {
      title: "MLB",
      route: "/auth/mlb",
      subItems: [
        { title: "Team Strikeouts per Game", route: "/auth/mlb/strikeouts" },
        { title: "Team Hits per Game", route: "/auth/mlb/hits" },
        { title: "ColdHot Matchup", route: "/auth/mlb/coldhot-matchup" },
        { title: "HotStreaks MLB", route: "/auth/mlb/hotstreaks-mlb" },
        { title: "1st Inning Runs", route: "/auth/mlb/first-inning-runs" },
        { title: "MLB Projections", route: "/auth/mlb/mlb-projections" },
      ],
    },
    {
      title: "+EV",
      route: "/auth/ev",
      subItems: [
        { title: "+ EV", route: "/auth/ev/ev" },
      ],
    },
    {
      title: "NFL",
      route: "/auth/nfl",
      subItems: [
        { title: "HotStreaks NFL", route: "/auth/nfl/hotstreaks-nfl" },
        { title: "Rushing Yards Allowed", route: "/auth/nfl/rushing-yards-allowed" },
        { title: "Pass Yards Allowed", route: "/auth/nfl/pass-yards-allowed" },
        { title: "NFL Projections", route: "/auth/nfl/nfl-projections" },
      ],
    },
  ];

  const toggleExpand = (index) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleSubItemClick = (subItemTitle) => {
    setSelectedSubItemTitle(subItemTitle); // Set clicked subitem's title
    handleClose(); // Close the sidebar if on a smaller screen
  };

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    toast.success("Logged out successfully."); // Success toast

    // Refresh the page to reset the application state
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000); // Delay to allow the toast message to be displayed
  };

  const location = useLocation();
  const currentPath = location.pathname.toLowerCase();

  useEffect(() => {
    const currentPath = location.pathname.toLowerCase();
    const newExpandedItems = {};
    let found = false;
  
    sidebarConfig.forEach((item, index) => {
      if (item.route.toLowerCase() === currentPath) {
        setSelectedSubItemTitle(item.title);
        found = true;
        // Expand this item if it has subItems
        newExpandedItems[index] = item.subItems ? true : false;
      } else if (item.subItems) {
        const subItemMatch = item.subItems.find(
          (subItem) => subItem.route.toLowerCase() === currentPath
        );
        if (subItemMatch) {
          setSelectedSubItemTitle(subItemMatch.title);
          newExpandedItems[index] = true; // Expand this parent item
          found = true;
        } else {
          newExpandedItems[index] = false; // Collapse this parent item
        }
      } else {
        newExpandedItems[index] = false; // Collapse items without subItems
      }
    });
  
    setExpandedItems(newExpandedItems);
  
    if (!found) {
      setSelectedSubItemTitle('');
    }
  }, [location.pathname]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileImgRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

   return (
    <>
      <div
        style={{
          width: isMdDown ? "100%" : "calc(100% - 240px)",
          padding: "15px",
          position: "fixed",
          top: "15px",
          right: "0",
          zIndex: "9",
          display: "flex",
          height: "50px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ position: "absolute", left: "15px", cursor: "pointer" }}>
          {isMdDown && (
            <FaBars
              onClick={toggleShow}
              className="me-2 d-md-block d-lg-none"
              size={28}
              color="white"
            >
              {name}
            </FaBars>
          )}
        </div>
  
        <h3
          style={{
            textAlign: "center",
            margin: "0",
            fontSize: isSmDown ? "18px" : isMdDown ? "20px" : "26px",
            flex: "1",
            paddingTop: "15%",
          }}
        >
          {selectedSubItemTitle}
        </h3>
  
        <div style={{ position: "absolute", right: "15px" }}>
          {/* Profile image */}
          <div
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              overflow: "hidden",
              boxShadow: "#FF4A17 0px 0px 10px 2px",
            }}
          >
            <img
              ref={profileImgRef}
              src="/images/avatar.webp"
              alt="Profile"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={toggleDropdown}
            />
          </div>
  
          {/* Dropdown Menu */}
          {showDropdown && (
            <div
              ref={dropdownRef}
              style={{
                position: "absolute",
                top: "60px",
                right: "2px",
                backgroundColor: "#333",
                color: "white",
                borderRadius: "5px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                width: "230px",
                padding: "5px",
              }}
            >
              <Link to="/auth/profile" onClick={() => setShowDropdown(false)}>
                <div style={{ padding: "10px", display: "flex", gap: "10px" }}>
                  <div style={{top: "0"}}><FaUser /></div>
                  Profile
                </div>
              </Link>
              <Link to="/auth/ChangePassword" onClick={() => setShowDropdown(false)}>
                <div style={{ padding: "10px", display: "flex", gap: "10px" }}>
                  <div style={{top: "0"}}><FaLock /></div>
                  Change Password
                </div>
              </Link>
              <Link to="/auth/billing-history" onClick={() => setShowDropdown(false)}>
                <div style={{ padding: "10px", display: "flex", gap: "10px" }}>
                  <div style={{top: "0"}}><FaRegClock /></div>
                  Billing History
                </div>
              </Link>
              <Link to="/auth/manage-subscription" onClick={() => setShowDropdown(false)}>
                <div style={{ padding: "10px", display: "flex", gap: "10px" }}>
                  <div style={{top: "0"}}><FaPen /></div>
                  Manage Subscription
                </div>
              </Link>
              <div
                style={{ padding: "10px", display: "flex", gap: "10px", cursor: "pointer" }}
                onClick={logOut}
              >
                <div style={{top: "0"}}><FaLock /></div>
                Logout
              </div>
            </div>
          )}
        </div>
  
        {/* Place the Join Referral Program button right beside the dropdown */}
        <div style={{ position: "absolute", right: "80px" }}>
          <ReferralProgramButton />
        </div>
      </div> {/* <-- Missing closing tag added here */}
  
      <Spacing lg="0" md="80" />
  
      {/* Offcanvas Sidebar */}
      <Offcanvas
        className="SideBarCustom"
        show={show || isLgUp}
        onHide={handleClose}
        {...props}
        style={{
          width: isMdDown ? "100%" : "250px",
          background: "#292929",
          color: "white",
        }}
      >
        <Offcanvas.Header style={{ padding: "0 20px" }} closeButton={isMdDown}>
          <Offcanvas.Title>
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "'Poppins'",
                fontWeight: "700",
                height: "100px",
              }}
              className="cs-site_branding"
              to="/auth"
              onClick={() => {
                handleClose();
                setSelectedSubItemTitle("Home");
              }}
            >
              <span
                style={{
                  fontSize: "89px",
                  marginTop: "0px",
                  fontFamily: "DM Sans",
                }}
                className="brandingSpan"
              >
                G
              </span>
              <div>
                <span className="brandingSpan" style={{ fontSize: "20px" }}>
                  AMBLING
                </span>
                <br />
                <span style={{ fontSize: "20px" }}>FORECAST</span>
              </div>
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <hr />
        <Offcanvas.Body style={{ marginBottom: "80px" }}>
          <Div>
            <ul className="SideBarNav" style={{ display: "block !important", padding: "0" }}>
              {sidebarConfig.map((item, index) => {
                // Determine if the item is active
                const isItemActive = item.route.toLowerCase() === currentPath;
  
                // Determine if any subitem is active
                let isSubItemActive = false;
                if (item.subItems) {
                  item.subItems.forEach((subItem) => {
                    if (subItem.route.toLowerCase() === currentPath) {
                      isSubItemActive = true;
                    }
                  });
                }
  
                return (
                  <li key={index} className="SidebarAnchor">
                    {/* Parent item row */}
                    <div
                      onClick={() => toggleExpand(index)}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px 0",
                        height: "55px",
                      }}
                    >
                      <Link
                        to={item.route}
                        onClick={() => {
                          handleClose();
                          setSelectedSubItemTitle(item.title);
                        }}
                        style={{
                          color: isItemActive ? "#fff" : "white",
                          textDecoration: "none",
                          fontWeight: isItemActive ? "bold" : "normal",
                        }}
                      >
                        {item.title}
                      </Link>
                      {item.subItems && item.subItems.length > 0 && (
                        <span>
                          {expandedItems[index] ? <FaChevronDown /> : <FaChevronRight />}
                        </span>
                      )}
                    </div>
  
                    {/* Subitems */}
                    <CSSTransition
                      in={expandedItems[index]}
                      timeout={300}
                      classNames="submenu"
                      unmountOnExit
                    >
                      <ul style={{ paddingLeft: "20px" }}>
                        {item.subItems.map((subItem, subIndex) => {
                          const isThisSubItemActive =
                            subItem.route.toLowerCase() === currentPath;
  
                          return (
                            <Link
                              to={subItem.route}
                              onClick={() => handleSubItemClick(subItem.title)}
                              style={{
                                fontSize: "14px",
                                color: isThisSubItemActive ? "#fff" : "#ccc",
                                textDecoration: "none",
                                fontWeight: isThisSubItemActive ? "bold" : "normal",
                              }}
                              key={subIndex}
                            >
                              <li className="list-item" style={{
                                borderLeft: isThisSubItemActive ? "2px solid rgb(226, 224, 223)" : "none",
                                }}>
                                  {subItem.title}
                              </li>
                            </Link>
                          );
                        })}
                      </ul>
                    </CSSTransition>
                    <hr />
                  </li>
                );
              })}
            </ul>
          </Div>
        </Offcanvas.Body>
  
        <div className="fixed-bottom">
          <button className="logout-button cs-btn cs-style1" onClick={logOut}>
            <span>Logout</span>
            <FaLock />
          </button>
        </div>
      </Offcanvas>
    </>
  );
}