let API_BASE_URL = "";

if (process.env.REACT_APP_MODE === 'production') {
    API_BASE_URL = process.env.REACT_APP_PROD; 
} else {
    API_BASE_URL = process.env.REACT_APP_DEV; 
}

// Authentication Endpoints
export const AUTH = {
  LOGIN: `${API_BASE_URL}/login/`, 
  REFRESH: `${API_BASE_URL}/token/refresh/`,
  REGISTER: `${API_BASE_URL}/register/`,
  PASSWORD_RESET: `${API_BASE_URL}/password-reset/`, 
  PASSWORD_RESET_CONFIRM: `${API_BASE_URL}/password-reset-confirm/`,
};

// User Profile Endpoints
export const PROFILE = {
  GET_UPDATE: `${API_BASE_URL}/profile/`,
  DELETE_IMAGE: `${API_BASE_URL}/profile/delete-image/`,
  CHANGE_PASSWORD: `${API_BASE_URL}/change-password/`,
};

// Subscription Endpoints (Payment)
export const SUBSCRIPTION = {
  STATUS: `${API_BASE_URL}/payment/subscription-status/`,
  LOGS: `${API_BASE_URL}/payment/subscription-logs/`,
  SUBSCRIBE: `${API_BASE_URL}/payment/subscribe/`,
  CANCEL: `${API_BASE_URL}/payment/cancel-subscription/`,
  CHANGE_PLAN: `${API_BASE_URL}/payment/change-subscription-plan/`,
  CHANGE_PAYMENT_METHOD: `${API_BASE_URL}/payment/change-payment-method/`,
};

// Plan Endpoints
export const PLAN = {
  LIST: `${API_BASE_URL}/payment/plans/`,
  DETAIL: (planId) => `${API_BASE_URL}/plans/${planId}/`,
};

// Additional Payment Endpoints
export const PAYMENT = {
  CREATE_CHECKOUT_SESSION: `${API_BASE_URL}/payment/create-checkout-session/`,
  STRIPE_WEBHOOK: `${API_BASE_URL}/payment/webhooks/stripe/`,
  CHANGE_PLAN: `${API_BASE_URL}/payment/change-subscription-plan/`,
};
