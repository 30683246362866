import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import PageHeading from "../../components/PageHeading"; 

const BillingHistory = () => {
  const [billingData, setBillingData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);


  // Load billing history data from localStorage
  useEffect(() => {
    const storedBillingHistory = localStorage.getItem("billing_history");
    try {
      const parsedData = JSON.parse(storedBillingHistory);
      if (Array.isArray(parsedData)) {
        setBillingData(parsedData);
      } else {
        setBillingData([]); // Default to empty array
      }
    } catch (error) {
      console.error("Error parsing billing history:", error);
      setBillingData([]); // Default to empty array
    }
  }, []);
  


  // Handle modal open and close
  const handleViewDetails = (record) => {
    setSelectedRecord(record);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRecord(null);
  };

  return (
    <>
      <PageHeading
        title="Billing History"
        bgSrc="/images/profile_hero_bg.jpeg"
        pageLinkText="Billing History"
        auth="/auth"
      />
      <div style={{ padding: "20px 40px", backgroundColor: "#181818" }}>
        <div
          className="row"
          style={{
            background: "#151515",
            borderRadius: "6px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              padding: "20px",
              background: "#212529",
            }}
          >
            <h4 style={{ fontFamily: "sans-serif", margin: "0 0" }}>
              Billing History
            </h4>
          </div>
          {billingData && billingData.length > 0 ? (
            <div className="col-md-12" style={{ background: "#212529" }}>
              <Table
                className="mt-3"
                striped
                bordered
                hover
                responsive
                variant="dark"
              >
                <thead>
                  <tr>
                    <th>Invoice ID</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {billingData.map((record, index) => (
                    <tr key={index}>
                      <td>{record.stripe_invoice_id}</td>
                      <td>{record.status}</td>
                      <td>{new Date(record.created_at).toLocaleDateString()}</td>
                      <td>${record.amount}</td>
                      <td>
                        <button
                          style={{
                            width: "150px",
                            height: "40px",
                            borderRadius: ".5rem",
                          }}
                          className="cs-btn cs-style1"
                          onClick={() => handleViewDetails(record)}
                        >
                          <span>View Details</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p style={{ textAlign: "center", color: "#fff" }}>
              No billing history available.
            </p>
          )}
        </div>
      </div>

      {/* Modal for Viewing Details */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        style={{ color: "white" }}
      >
        <Modal.Header
          closeButton
          style={{
            background: "#181818",
            borderBottom: "1px solid #ff6600",
          }}
        >
          <Modal.Title>Billing Details</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: "#181818",
            color: "white",
          }}
        >
          {selectedRecord ? (
            <div>
              <p>
                <strong>Invoice ID:</strong> {selectedRecord.stripe_invoice_id}
              </p>
              <p>
                <strong>Payment Intent ID:</strong>{" "}
                {selectedRecord.payment_intent_id}
              </p>
              <p>
                <strong>Amount:</strong> ${selectedRecord.amount}
              </p>
              <p>
                <strong>Currency:</strong> {selectedRecord.currency}
              </p>
              <p>
                <strong>Status:</strong> {selectedRecord.status}
              </p>
              <p>
                <strong>Description:</strong>{" "}
                {selectedRecord.description || "N/A"}
              </p>
              <p>
                <strong>Date:</strong>{" "}
                {new Date(selectedRecord.created_at).toLocaleString()}
              </p>
            </div>
          ) : (
            <p>No details available.</p>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            background: "#181818",
            borderTop: "1px solid #ff6600", // Orange highlight
          }}
        >
          <Button
            variant="outline-warning"
            onClick={handleCloseModal}
            style={{
              background: "transparent",
              border: "1px solid #ff6600", // Orange border
              color: "#ff6600",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default BillingHistory;
