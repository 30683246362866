import { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosRest';

const useFetchPlans = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axiosInstance.get('payment/plans/');
        setPlans(response.data);
      } catch (err) {
        console.error("Error fetching plans:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  return { plans, loading, error };
};

export default useFetchPlans;
