import React, { useEffect } from "react";
import { pageTitle } from "../helper";
import Cta from "../components/Cta";
import PageHeading from "../components/PageHeading";
import Div from "../components/Div";
import SectionHeading from "../components/SectionHeading";
import TeamSlider from "../components/Slider/TeamSlider";
import Spacing from "../components/Spacing";
import Button from "../components/Button";

const funfaceData = [
  {
    title: "Global Happy Clients",
    factNumber: "40K",
  },
  {
    title: "Project Completed",
    factNumber: "50K",
  },
  {
    title: "Team Members",
    factNumber: "245",
  },
  {
    title: "Digital products",
    factNumber: "550",
  },
];

export default function AboutPage() {
  pageTitle("About");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6">
            <SectionHeading
              title="What we do?"
              subtitle="About Us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Gambling Forecast originally started as a free twitter page “JBoogie Bets”. After 3
              years of creating new data models and publicly tracking their successful results, the
              models were fully automated and the website Gambling Forecast was created.
              Gambling Forecast is designed to elevate your daily gambling experience by gathering
              the most relevant sports information and displaying it all in one place. Our goal is to
              ensure every bet is placed with the value in your favor.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-xl-6 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
        </Div>

      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" /> */}
      <Div className="container">
        <SectionHeading
          title="Founder"
          subtitle="Team"
          variant="cs-style1"
        />
        <Spacing lg="20" md="20" />
        <TeamSlider />
      </Div>
      {/* End Team Section */}
      <Spacing lg="20" md="20" />
      <Div className="container text-center">

        <Button btnLink="/pricing" btnText="Pricing" className="custom-login"/>
      </Div>
    </>
  );
}
