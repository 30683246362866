import React, { useState, useEffect } from "react";
import ReferralProgramModal from "./ReferralProgramModal";
import axiosInstance from "../../api/axiosRest";
import { FaStar } from "react-icons/fa"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ReferralProgramButton.css";

export default function ReferralProgramButton() {
  const [hasJoined, setHasJoined] = useState(false);
  const [credits, setCredits] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("terms");

  useEffect(() => {
    async function fetchStatus() {
      try {
        const response = await axiosInstance.get("/referral-status/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        setHasJoined(response.data.has_joined_referral);
        setCredits(response.data.credits);
      } catch (error) {
        console.error("Error fetching referral status:", error);
      }
    }
    fetchStatus();
  }, []);

  const handleButtonClick = () => {
    if (!hasJoined) {
      setModalMode("terms");
      setModalOpen(true);
    } else {
      setModalMode("dashboard");
      setModalOpen(true);
    }
  };

  const handleAcceptTerms = async () => {
    try {
      const response = await axiosInstance.post("/join-referral/", {});
      toast.success(response.data.detail || "Referral program joined!");
      setHasJoined(true);
      setModalOpen(false);
    } catch (error) {
      console.error("Error joining referral program:", error);
      toast.error(
        error.response?.data?.detail || "Failed to join referral program."
      );
    }
  };

  const modeClass = hasJoined ? "credits-mode" : "join-mode";

  return (
    <>
      <ToastContainer />
      <button
        type="button"
        onClick={handleButtonClick}
        className={`referral-button ${modeClass}`}
      >
        {/* =========================
            IF NOT JOINED
            Large screens => text + static stars
            Small screens => circle with rotating stars
         ==========================*/}
        {!hasJoined ? (
          <>
            {/* Show text + static stars only on large screens */}
            <div className="join-content-desktop">
              <span>Join Referral Program</span>
              <FaStar className="star" />
              <FaStar className="star" />
              <FaStar className="star" />
            </div>
            {/* Show rotating stars only on small screens */}
            <div className="join-content-mobile">
              <div className="rotating-stars">
                <FaStar className="star star1" />
                <FaStar className="star star2" />
                <FaStar className="star star3" />
              </div>
            </div>
          </>
        ) : (
          /* IF ALREADY JOINED => credits mode */
          <div className="credits-inner">
            <span className="credits-label">Credits:</span>
            <span className="credits-count">{credits}</span>
          </div>
        )}
      </button>

      {modalOpen && (
        <ReferralProgramModal
          mode={modalMode}
          onClose={() => setModalOpen(false)}
          onAcceptTerms={handleAcceptTerms}
          currentCredits={credits}
        />
      )}
    </>
  );
}
